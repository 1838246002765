<template>
  <b-col cols="12">
    <div class="timeline-item">
      <div class="time-post">
        <span class="time">{{ time }}</span>
      </div>
      <div class="infos-post">
        <div class="categories-post">
          <span class="label">
            {{ resource.type }}
          </span>
        </div>
        <div class="description-post">
          <button v-if="resource.extension_type == '.pdf'" @click="onOpenPreviewModal" class="preview-pdf">
            <FileIcon color="#DB3C3C" :extenssion="resource.extension_type" />
            <span class="label">Visualizar</span>
          </button>
          <p v-if="resource.general_type == 'followup'">
            {{ resource.content }}
          </p>
          <img @click="onOpenPreviewModal" v-if="
            resource.extension_type == '.png' ||
            resource.extension_type == '.jpg' ||
            resource.extension_type == '.gif'
          " :src="resource.link" alt="" />
        </div>
        <div v-if="edit" class="actions-post">
          <button @click="editItem">editar</button>
          <button @click="removeItem">excluir</button>
        </div>
      </div>
      <span class="flag" v-if="
        resource.general_type == 'followup' &&
        !resource.visible_for_parents &&
        edit
      ">
        Não visível para os responsáveis
      </span>
    </div>
    <!-- Modal -->
    <FilesViewModal :opening="showPreview" @onClose="onClosePreviewModal" :type="resource.extension_type"
      :path="resource.link" />
  </b-col>
</template>

<script>
import FileIcon from "@/components/svg/IconFiles.vue";
import FilesViewModal from "@/components/modals/FilesViewModal.vue";
import moment from "moment-timezone";

export default {
  name: "TimelineItem",
  components: {
    FileIcon,
    FilesViewModal,
  },

  props: {
    key: null,
    resource: null,
    edit: {
      type: Boolean,
      default: true,
    },
    onRemove: {
      type: Function,
      default: null,
    },
    onEdit: {
      type: Function,
      default: null,
    },
  },

  data: () => {
    return {
      showPreview: false,
    };
  },

  computed: {
    time() {
      const timePost = moment(this.resource.created_at).format("DD.MM.YY");
      return timePost;
    },
  },

  methods: {
    removeItem() {
      this.$emit("onRemove", this.resource);
    },

    editItem() {
      this.$emit("onEdit", this.resource);
    },

    onOpenPreviewModal() {
      this.showPreview = true;
    },
    onClosePreviewModal() {
      this.showPreview = false;
    },
  },
};
</script>

<style lang="scss">
.timeline-item {
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  display: flex;
  align-items: flex-startx;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 10px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  .flag {
    font-size: 12px;
    color: var(--purple);
    border: 1px solid var(--purple);
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 50px;
  }

  .time-post {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    margin-right: 20px;

    .time {
      font-size: 16px;
      color: #666;
    }
  }

  .infos-post {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    .categories-post {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        font-size: 16px;
        color: #666;
      }
    }

    .description-post {
      p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      img {
        max-width: 60%;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .preview-pdf {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      background: rgba(219, 60, 60, 0.1);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      border: 1px solid rgba(219, 60, 60, 0.2);
      margin-bottom: 10px;
      padding: 5px 10px;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: rgba(219, 60, 60, 0.15);
      }

      .label {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 24px;
      }
    }

    .actions-post {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
      box-sizing: border-box;

      button {
        text-transform: capitalize;
        color: #b23066;
        background-color: transparent;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        border: 1px solid transparent;
        padding: 2px 10px;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;

        &:hover {
          border-color: #b23066;
        }
      }
    }
  }
}
</style>
