<template>
  <div class="loadingView" v-if="loadingForm">
    <LoadingList v-model="loadingForm" text="Carregando dados." />
  </div>
  <div v-else>
    <b-card-header header-tag="header" role="tab">
      <h5 v-b-toggle.accordion-1>Informações do Aluno</h5>
    </b-card-header>
    <b-collapse
      id="accordion-1"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-row>
        <b-col md="2">
          <ProfilePhoto
            modalId="studentData"
            :photo="
              typeof value.photo !== 'string' ? photoStudent : value.photo
            "
            :callback="setNewPhoto"
          />
        </b-col>
        <b-col md="10">
          <b-row>
            <b-col md="6">
              <StandardInput
                v-model="value.name"
                class="required-cme required"
                type="text"
                name="name"
                label="Nome completo"
                :error="nameStudantValidate"
                labelError="Digite nome e sobrenome"
                placeholder
                validateFullName
              />
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <StandardInput
                  v-model="value.birth_date"
                  class="required-cme required"
                  type="text"
                  name="birth_date"
                  label="Data de nascimento"
                  v-mask="'##/##/####'"
                  placeholder
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <label for="label">País de origem</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.country_origin"
                  :options="options.country"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <label for="label">Nacionalidade</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.nationality"
                  :options="options.country"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <StandardInput
                v-model="value.city_origin"
                class="required-cme required"
                type="text"
                name="cidade"
                label="Município"
                placeholder
              />
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <label for="label">UF</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.state_origin"
                  :options="options.uf"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group class="required">
                <label for="label">Localização/Zona da residência</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.residence_zone"
                  :options="options.residences"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group class="required-cme required">
                <label for="label">Mora com</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.lives_with"
                  :options="options.livesWith"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group class="required-cme required">
                <label for="label">Guarda do aluno</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.guardian"
                  :options="options.custodies"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <label for="label">Etnia</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.ethnicity"
                  :options="options.ethnicities"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <label for="label">Sexo</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.gender"
                  :options="options.genders"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="required-cme required">
                <label for="label">Cor</label>
                <multiselect
                  open-direction="bottom"
                  v-model="value.skin_color"
                  :options="options.skinColors"
                  :multiple="false"
                  placeholder="Selecione"
                  selectLabel
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-collapse>

    <!-- Block -->
    <b-card-header header-tag="header" role="tab">
      <h5 v-b-toggle.accordion-2>Irmãos</h5>
    </b-card-header>
    <b-collapse
      id="accordion-2"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-row class="mb-3">
        <b-col md="3">
          <fieldset class="form-group required">
            <legend></legend>
            <label for="hasSiblings">Possui irmão</label>
            <b-form-select
              v-model="hasSiblings"
              id="hasSiblings"
              :options="hasSiblingsOptions"
              @change="setSiblings(value.EnrollmentStudentBrothers)"
            />
          </fieldset>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <template v-if="value.EnrollmentStudentBrothers.length != 0">
          <b-col>
            <StandardInput
              v-model="value.EnrollmentStudentBrothers[0].name"
              type="text"
              name="name"
              label="Nome"
              placeholder
            />
          </b-col>
          <b-col md="2">
            <StandardInput
              v-model="value.EnrollmentStudentBrothers[0].age"
              type="number"
              name="age"
              label="Idade"
              placeholder
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="value.EnrollmentStudentBrothers[0].school"
              type="text"
              name="school"
              label="Escola"
              placeholder
            />
          </b-col>
        </template>
      </b-row>
      <div v-if="value.EnrollmentStudentBrothers.length != 0">
        <b-row
          v-for="(sibling, i) in value.EnrollmentStudentBrothers.slice(1)"
          :key="i"
          class="mb-3"
        >
          <b-col>
            <StandardInput
              v-model="value.EnrollmentStudentBrothers[i + 1].name"
              type="text"
              name="name"
              label="Nome"
              placeholder
            />
          </b-col>
          <b-col md="2">
            <StandardInput
              v-model="value.EnrollmentStudentBrothers[i + 1].age"
              type="number"
              name="age"
              label="Idade"
              placeholder
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="value.EnrollmentStudentBrothers[i + 1].school"
              type="text"
              name="school"
              label="Escola"
              placeholder
            />
          </b-col>
          <b-col md="1" class="delete-col">
            <span @click="() => removeSiblingFromList(i)" class="icon-delete">
              <DeleteIcon />
            </span>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col md="3">
          <b-button
            @click="addSibling"
            :class="{ disabled: hasSiblings == 'nao' }"
            class="btn large blue mb-5 add-brother"
            v-b-modal.change-password
          >
            <b-icon-plus-lg /> Adicionar irmão
          </b-button>
        </b-col>
      </b-row>
    </b-collapse>

    <!-- Block -->
    <b-card-header header-tag="header" role="tab">
      <h5 v-b-toggle.accordion-3>Matrícula</h5>
    </b-card-header>
    <b-collapse
      id="accordion-3"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <p class="purple">Período e ano a frequentar.</p>

      <div class="status-box">
        <p class="status-style">
          <button
            class="actives"
            @click.prevent="getPeriod('Matutino')"
            :class="{
              active: this.time_period_status === 'Matutino',
            }"
          >
            Manhã
          </button>
          <button
            class="inactives"
            @click.prevent="getPeriod('Vespertino')"
            :class="{
              active: this.time_period_status === 'Vespertino',
            }"
          >
            Tarde
          </button>
        </p>
        <p class="info" v-if="rdaUnit != 'POUSO_ALEGRE'">
          Obs: A partir do 7° ano (F3A) os alunos da manhã também têm aulas no
          período da tarde. Para mais informações, contate a Escola.
        </p>
      </div>

      <b-row>
        <b-col md="3">
          <b-form-group class="required">
            <label for="label">Etapa escolar</label>
            <div
              v-if="
                this.options_time_period[
                  this.time_period_status === 'Matutino' ? 0 : 1
                ][this.time_period_status].length > 0
              "
            >
              <multiselect
                open-direction="bottom"
                v-model="value.selected_time_period"
                :options="
                  this.time_period_status === 'Matutino'
                    ? this.options_time_period[0]['Matutino']
                    : this.options_time_period[1]['Vespertino']
                "
                :multiple="false"
                placeholder="Selecione"
                :selectLabel="false"
              />
            </div>
            <div v-else>
              <p class="purple" style="font-size: 14px">
                O ciclo da rematrícula não está disponível no turno selecionado
              </p>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        class="row-enrollment"
        v-if="
          value.selected_time_period == 'Infantil' &&
          arrCycles[value.time_period.toLowerCase()].infantil &&
          arrCycles[value.time_period.toLowerCase()].infantil.length > 0
        "
      >
        <b-col>
          <b-form-group class="group-radio">
            <b-form-radio
              v-model="value.grade"
              name="matricula"
              v-for="(item, index) in arrCycles[
                this.time_period_status.toLowerCase()
              ].infantil"
              :key="index"
              :value="item.grade"
            >
              {{
                is_reenrollment
                  ? item.reenrollment_description
                  : item.enrollment_description
              }}
              <span v-if="value.time_period === 'Vespertino'" class="gradient1"
                >- Tarde</span
              >
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        class="row-enrollment"
        v-if="
          value.selected_time_period == 'Ensino fundamental' &&
          arrCycles[value.time_period.toLowerCase()].fundamental &&
          arrCycles[value.time_period.toLowerCase()].fundamental.length > 0
        "
      >
        <b-col>
          <b-form-group class="group-radio" label="Ensino fundamental">
            <b-form-radio
              v-for="(item, index) in arrCycles[
                this.time_period_status.toLowerCase()
              ].fundamental"
              :key="index"
              v-model="value.grade"
              name="matricula"
              :value="item.grade"
            >
              {{
                is_reenrollment
                  ? item.reenrollment_description
                  : item.enrollment_description
              }}
              <span v-if="value.time_period === 'Vespertino'" class="gradient1"
                >- Tarde</span
              >
            </b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="
          value.time_period === 'Matutino' &&
          value.selected_time_period == 'Ensino médio' &&
          arrCycles[value.time_period.toLowerCase()].ensinoMedio &&
          arrCycles[value.time_period.toLowerCase()].ensinoMedio.length > 0
        "
        class="row-enrollment"
      >
        <b-col>
          <b-form-group class="group-radio">
            <b-form-radio
              v-for="(item, index) in arrCycles[
                this.time_period_status.toLowerCase()
              ].ensinoMedio"
              :key="index"
              v-model="value.grade"
              name="matricula"
              :value="item.grade"
            >
              {{
                is_reenrollment
                  ? item.reenrollment_description
                  : item.enrollment_description
              }}
              <span v-if="value.time_period === 'Vespertino'" class="gradient1"
                >- Tarde</span
              ></b-form-radio
            >
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <StandardInput
            v-model="value.previous_school"
            type="text"
            name="escola"
            label="Escola anterior (Deixar em branco caso não possua)"
            placeholder
            :required="false"
          />
        </b-col>
      </b-row>
    </b-collapse>

    <!-- Block -->
    <b-card-header header-tag="header" role="tab">
      <h5 v-b-toggle.accordion-4>Dados de endereço</h5>
    </b-card-header>
    <b-collapse
      id="accordion-4"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <div v-if="!loadingCep">
        <b-row>
          <b-col>
            <StandardInput
              v-model="value.Address.cep"
              class="required required-cme"
              type="text"
              name="cep"
              label="CEP"
              v-mask="'#####-###'"
              placeholder
              @onchange="getAddress(value.Address.cep)"
            />
          </b-col>
          <b-col>
            <b-form-group class="required required-cme">
              <label for="label">Estado</label>
              <multiselect
                open-direction="bottom"
                v-model="value.Address.state"
                :options="options.uf"
                :multiple="false"
                placeholder="Selecione um estado"
                selectLabel
              />
            </b-form-group>
          </b-col>
          <b-col>
            <StandardInput
              v-model="value.Address.city"
              class="required required-cme"
              type="text"
              name="city"
              label="Cidade"
              placeholder
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="value.Address.neighborhood"
              class="required required-cme"
              type="text"
              name="neighborhood"
              label="Bairro"
              placeholder
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <StandardInput
              v-model="value.Address.street"
              class="required required-cme"
              type="text"
              name="address"
              label="Endereço"
              placeholder
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="value.Address.number"
              class="required-cme"
              type="text"
              name="number"
              label="Número"
              placeholder
            />
          </b-col>
          <b-col>
            <StandardInput
              v-model="value.Address.complement"
              type="text"
              name="complement"
              label="Complemento"
              placeholder
              :required="false"
            />
          </b-col>
          <b-col></b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row align="center">
          <b-col class="mt-5">
            <b-spinner
              variant="success"
              label="Pesquisando o CEP..."
            ></b-spinner>
          </b-col>
        </b-row>
      </div>
    </b-collapse>

    <!-- Block -->
    <b-card-header header-tag="header" role="tab">
      <h5 v-b-toggle.accordion-5>Contatos</h5>
    </b-card-header>
    <b-collapse
      id="accordion-5"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-row>
        <b-col>
          <StandardInput
            v-model="value.phone1"
            class="required-cme required"
            type="text"
            name="phone-1"
            label="Telefone 1"
            v-mask="'(##) #####-####'"
            placeholder
          />
        </b-col>
        <b-col>
          <StandardInput
            v-model="value.phone2"
            type="text"
            name="phone-2"
            label="Telefone 2"
            v-mask="'(##) #####-####'"
            placeholder
            :required="false"
          />
        </b-col>
        <b-col>
          <StandardInput
            v-model="value.email"
            class="required-cme required mb-4"
            type="email"
            name="email"
            label="E-mail do Aluno"
            placeholder
            :disabled="studentHasNoEmail ? true : false"
          />

          <b-form-checkbox @change="changeEmail()"
            >Aluno menor de 10 anos e não possui e-mail.</b-form-checkbox
          >
        </b-col>
      </b-row>
    </b-collapse>

    <!-- Block -->
    <b-card-header header-tag="header" role="tab">
      <h5 v-b-toggle.accordion-6>Documentos</h5>
    </b-card-header>
    <b-collapse
      id="accordion-6"
      visible
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-row>
        <b-col md="4">
          <fieldset class="form-group required-cme required">
            <legend></legend>
            <label for="hasSiblings">CPF</label>
            <b-form-input
              v-model="value.cpf"
              :state="verificarCpf"
              type="text"
              name="cpf"
              label="CPF"
              v-mask="'###.###.###-##'"
              placeholder
            ></b-form-input>
          </fieldset>
        </b-col>
        <b-col md="12">
          <p class="purple">Preencha ao menos um dos campos abaixo</p>
        </b-col>
        <b-col md="4">
          <StandardInput
            v-model="value.birth_certificate"
            class="required"
            type="text"
            name="birth-certificate"
            label="Certidão de nascimento"
            placeholder
            :required="false"
          />
        </b-col>
        <b-col md="4">
          <StandardInput
            v-model="value.rg"
            class="required"
            type="text"
            name="rg"
            label="RG"
            placeholder
            :required="false"
          />
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import * as lang from "vuejs-datepicker/src/locale";
import utils from "@/utils";
import ProfilePhoto from "@/components/ProfilePhoto.vue";
import StandardInput from "@/components/inputs/StandardInput.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import getAddressByCep from "@/services/getAddressByCep";
import httpRda from "../../../http-rda";
import getStateNameByAcronym from "../../../utils/getStateNameByAcronym";
import LoadingList from "@/components/LoadingList.vue";

export default {
  name: "StudentData",
  components: {
    ProfilePhoto,
    StandardInput,
    DeleteIcon,
    LoadingList,
  },

  props: ["value", "nameStudantValidate"],

  data() {
    return {
      loadingForm: true,
      loadingCep: false,
      hasSiblings: null,
      arrCycles: null,
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      is_reenrollment: this.$route.name.includes("rematricula") ? true : false,
      enrollment_id: this.$route.params.id,
      options_time_period: [
        {
          Matutino: [],
        },
        {
          Vespertino: [],
        },
      ],
      selected_time_period: null,
      photoStudent: null,
      language: "ptBR",
      languages: lang,
      rawDate: "",
      studentHasNoEmail: false,
      time_period_status: "Matutino",
      disabledDates: {
        from: new Date(),
      },
      hasSiblingsOptions: [
        { value: "nao", text: "Não" },
        { value: "sim", text: "Sim" },
      ],
    };
  },

  methods: {
    resetGrade() {
      this.value.grade = null;
      this.value.selected_time_period = null;
    },
    // Form
    addSibling() {
      this.value.EnrollmentStudentBrothers.push({
        name: "",
        age: "",
        school: "",
      });
    },

    async removeSiblingFromList(index) {
      try {
        await httpRda.delete(
          `/enrollments/${this.value.enrollment_id}/student/brothers/${
            this.value.EnrollmentStudentBrothers[index + 1].id
          }`
        );
      } catch (error) {
        return;
      }
      const newList = [...this.value.EnrollmentStudentBrothers];
      newList.splice(index + 1, 1);
      this.value.EnrollmentStudentBrothers = newList;
    },

    setSiblings() {
      if (this.hasSiblings == "nao") {
        this.value.EnrollmentStudentBrothers = [];
      } else {
        this.value.EnrollmentStudentBrothers = [
          { name: "", age: "", school: "" },
        ];
      }
    },

    loadSiblings() {
      if (
        this.value.EnrollmentStudentBrothers != undefined &&
        this.value.EnrollmentStudentBrothers.length
      ) {
        this.value.hasSiblings = true;
      } else {
        this.value.hasSiblings = false;
        this.value.EnrollmentStudentBrothers = [];
      }

      this.loadingForm = false;
    },

    setNewPhoto(photo) {
      const bodyFormData = new FormData();
      bodyFormData.append("file", photo);
      this.value.photo = bodyFormData;

      this.photoStudent = photo;
    },

    getPeriod(period) {
      this.resetGrade();
      this.time_period_status = period;
      this.value.time_period = period;
      this.selected_time_period = null;
    },

    changeEmail() {
      this.studentHasNoEmail = !this.studentHasNoEmail;

      if (this.studentHasNoEmail) {
        const name =
          this.value.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, "_")
            .toLowerCase() + "@escoladaserra.com.br";

        this.value.email = name;
      } else {
        this.value.email = "";
      }
    },

    async getAddress(cep) {
      this.loadingCep = true;
      await getAddressByCep(cep)
        .then((resp) => {
          this.value.Address.state = getStateNameByAcronym(resp.uf);
          this.value.Address.city = resp.localidade;
          this.value.Address.neighborhood = resp.bairro;
          this.value.Address.street = resp.logradouro;
          this.loadingCep = false;
        })
        .catch((err) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: err.message,
          });
        });
      this.loadingCep = false;
    },

    getGrades() {
      httpRda.get(`cycles-to-enrollment`).then((resp) => {
        this.arrCycles = resp.data.cycles;

        if (this.arrCycles) {
          if (this.arrCycles.matutino) {
            for (const key in this.arrCycles.matutino) {
              switch (key) {
                case "infantil":
                  this.options_time_period[0]["Matutino"].push("Infantil");
                  break;
                case "fundamental":
                  this.options_time_period[0]["Matutino"].push(
                    "Ensino fundamental"
                  );
                  break;

                case "ensinoMedio":
                  this.options_time_period[0]["Matutino"].push("Ensino médio");
                  break;
                default:
                  break;
              }
            }
          }

          if (this.arrCycles.vespertino) {
            for (const key in this.arrCycles.vespertino) {
              switch (key) {
                case "infantil":
                  this.options_time_period[1]["Vespertino"].push("Infantil");
                  break;
                case "fundamental":
                  this.options_time_period[1]["Vespertino"].push(
                    "Ensino fundamental"
                  );
                  break;

                case "ensinoMedio":
                  this.options_time_period[1]["Vespertino"].push(
                    "Ensino médio"
                  );
                  break;
                default:
                  break;
              }
            }
          }
        }
      });
    },
  },

  computed: {
    propModel: {
      get() {
        return this.prop;
      },
      set(value) {
        this.$emit("update:prop", value);
      },
    },

    // Form
    options() {
      return utils.options;
    },

    verificarCpf() {
      if (this.value.cpf != undefined) {
        const validarCPF = utils.validateCPF(this.value.cpf);
        return validarCPF ? true : false;
      } else {
        return false;
      }
    },
  },

  watch: {
    hasSiblings() {},
  },

  mounted() {
    this.getGrades();
    this.loadSiblings();

    if (this.value.time_period) {
      this.time_period_status = this.value.time_period;
    } else {
      this.value.time_period = "Matutino";
    }

    this.hasSiblings =
      this.value.EnrollmentStudentBrothers.length > 0 ? "sim" : "nao";
  },
};
</script>

<style lang="scss" scoped></style>
