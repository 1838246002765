<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold title">
          Alunos <span class="tags_count">{{ count }}</span>
        </h2>
      </b-col>
      <b-col class="text-right" v-if="canEdit">
        <b-button
          class="btn large blue"
          :disabled="!students || !students.length"
          @click="downloadStudentReport(null)"
          >Exportar dados dos alunos</b-button
        >

        <router-link
          style="margin-left: 35px"
          class="btn large blue"
          :to="{ name: 'emitir-carteirinha' }"
          >Emitir carteirinha</router-link
        >
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col>
        <SelectCycles v-model="cycle" :callback="callbackCycles"></SelectCycles>
        <span class="clear-field" v-if="this.cycle != null" @click="clearCycles"
          >Limpar filtro</span
        >
      </b-col>
      <b-col>
        <SelectClassrooms
          v-model="classroom"
          :callback="callbackClassrooms"
          :cycle="cycle_select"
        ></SelectClassrooms>
        <span
          class="clear-field"
          v-if="this.classroom != null"
          @click="clearClassromms"
          >Limpar filtro</span
        >
      </b-col>

      <b-col>
        <SelectStudentsStatus
          v-model="is_active"
          :callback="callbackStudentsStatus"
        ></SelectStudentsStatus>
        <span
          class="clear-field"
          v-if="this.is_active != null"
          @click="clearStudentsStatus"
          >Limpar filtro</span
        >
      </b-col>

      <b-col>
        <b-form-group label="Buscar aluno">
          <b-form-input
            type="search"
            size="sm"
            placeholder="Digite aqui"
            v-model="search"
            @change="searchStudent"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-container class="list-top" fluid>
          <b-row
            class="header-list-teacher"
            v-if="wasRequestMade && students.length"
          >
            <b-col :md="3">
              <p class="bold">Nome</p>
            </b-col>
            <b-col>
              <p class="bold">Ciclo</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Ativo</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Editar senha</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Editar</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Apagar</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Visualizar</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Exportar</p>
            </b-col>
            <b-col>
              <p class="bold">{{ nameCol }}</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Edit. Matrícula</p>
            </b-col>
            <b-col v-if="canEdit">
              <p class="bold">Exp. aluno</p>
            </b-col>
          </b-row>
        </b-container>

        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="student in students"
            :key="student.id"
          >
            <b-col :md="3" class="name">
              <p class>{{ student.User.name }}</p>
            </b-col>

            <b-col class="cycles">
              <p class="tags">{{ student.Cycle.name }}</p>
            </b-col>

            <b-col v-if="canEdit" class="status">
              <b-form-checkbox
                switch
                v-if="student.is_active"
                v-model="student.is_active"
                @input="updateStatus(student.id, false)"
              ></b-form-checkbox>
              <b-form-checkbox
                switch
                v-else
                v-model="student.is_active"
                @input="updateStatus(student.id, true)"
              ></b-form-checkbox>
            </b-col>

            <b-col v-if="canEdit" class="editar-senha">
              <a
                @click="() => setStudentPassword(student, student.id)"
                v-b-modal.edit-password
              >
                <LockIcon />
              </a>
            </b-col>

            <b-col v-if="canEdit">
              <a
                @click="() => setStudent(student, student.id)"
                v-b-modal.student-data
              >
                <EditIcon />
              </a>
            </b-col>

            <b-col v-if="canEdit">
              <a @click="() => confirmStudentDeletion(student.id)">
                <DeleteIcon />
              </a>
            </b-col>

            <b-col v-if="canEdit">
              <router-link
                :to="{
                  name: 'perfil-aluno',
                  params: { id: student.id },
                }"
              >
                <ViewIcon />
              </router-link>
            </b-col>

            <b-col v-if="canEdit">
              <router-link
                :to="{
                  name: 'exportar-relatorio-desenvolvimento',
                  params: { id: student.id },
                }"
              >
                <ExportIcon />
              </router-link>
            </b-col>

            <b-col
              v-if="
                (student.Cycle && student.Cycle.id == 8) ||
                student.Cycle.id == 6
              "
            >
              <router-link
                :to="{
                  name: 'dashboard-timeline-aluno',
                  params: { id: student.id },
                }"
              >
                <ViewIcon2 />
              </router-link>
            </b-col>
            <b-col v-else>
              <router-link
                :to="{
                  name: 'dashboard-tutoria-aluno',
                  params: { id: student.id },
                }"
              >
                <ViewIcon2 />
              </router-link>
            </b-col>
            <b-col v-if="canEdit">
              <router-link
                :to="{
                  name: 'dadosMatricula',
                  params: { id: student.id },
                }"
                target="_blank"
              >
                <EditIcon />
              </router-link>
            </b-col>

            <b-col v-if="canEdit">
              <b-button
                @click="downloadStudentReport(student.id)"
                target="_blank"
                style="background-color: transparent; border: 0"
              >
                <ExportIcon />
              </b-button>
            </b-col>
          </b-row>

          <b-row
            align-v="center"
            class="list-teacher"
            v-if="wasRequestMade && !students.length"
          >
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!wasRequestMade">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row class="align-center" v-if="!search && wasRequestMade">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="count"
              aria-controls="teachersList"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>

          <StudentEditData :student="studentToEdit" />

          <StudentEditPassword :student="studentToPassword" />
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataService from "@/services/students.service";

import ViewIcon from "@/components/svg/ViewIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import ExportIcon from "@/components/svg/ExportIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import ViewIcon2 from "@/components/svg/ViewIcon2.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import LockIcon from "@/components/svg/LockIcon.vue";
import SelectClassrooms from "@/components/inputs/SelectClassrooms.vue";
import SelectStudentsStatus from "@/components/inputs/SelectStudentsStatus.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import StudentEditData from "@/components/modals/StudentEditData.vue";
import StudentEditPassword from "@/components/modals/StudentEditPassword.vue";
import HttpRda from "../../../http-rda";
import moment from "moment-timezone";
export default {
  name: "alunos",
  components: {
    ViewIcon,
    EditIcon,
    ExportIcon,
    ViewIcon2,
    DeleteIcon,
    LeftArrowIcon,
    LockIcon,
    SelectClassrooms,
    SelectStudentsStatus,
    SelectCycles,
    StudentEditData,
    StudentEditPassword,
  },

  data() {
    return {
      nameCol: "Tutoria",
      descOrder: false,
      wasRequestMade: false,
      studentToEdit: {},
      studentToPassword: {},
      search: "",
      filter: null,
      cycle_select: null,
      cycle: null,
      classroom: null,
      is_active: null,
      searchTerm: "",
      students: null,
      currentPage: 1,
      count: 0,
      perPage: 10,

      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Alunos",
          href: "/alunos",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),

    canEdit() {
      if (!this.user.role) return false;
      return !["teacher", "student", "parent"].includes(this.user.role[0]);
    },
  },

  methods: {
    ...mapActions({
      getStudent: "community/getStudent",
      deleteStudent: "community/deleteStudent",
      updateStatusStudents: "community/updateStatusStudents",
    }),

    async downloadStudentReport(studentId = null) {
      let url;

      if (studentId) {
        url = `/students/data_report?id=${studentId}`;
      } else {
        url = `/students/data_report`;
      }

      try {
        const response = await HttpRda.get(url, {
          params: this.filter,
          responseType: "blob",
        });

        const date = moment().format("DD-MM-YYYY-HH:mm:ss").toString();

        const newBlob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        let data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = `Relatorio_de_alunos_${date}`;
        link.click();

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
        }, 100);

        const Toast = this.$swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: `Relatório exportado com sucesso!`,
        });

        return response;
      } catch (error) {
        this.loadText = "Exportar";
        this.subjectsSelected = [];

        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel exportar o relatório!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error;
      }
    },

    orderbyName() {
      const data = this.students.filter((d) => d.User !== null);
      return data.sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];
        if (a.User.name < b.User.name) return order[0];
        else if (a.User.name > b.User.name) return order[1];

        return 0;
      });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.cycle_select = id;
      if (this.cycle_select == 8 || this.cycle_select == 6) {
        this.nameCol = "Ed. Infantil";
      } else {
        this.nameCol = "Tutoria";
      }
      this.currentPage = 1;
      this.clearClassromms();
      this.listStudents();
    },

    callbackClassrooms({ id }) {
      this.classroom = id !== -1 ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    callbackStudentsStatus({ id }) {
      this.is_active = id !== null ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    async listStudents(page = 1) {
      const params = {
        limit: this.perPage,
        page,
        search: this.search,
        cycle: this.cycle,
        is_active: this.is_active,
        classroom: this.classroom,
      };

      if (this.search) {
        delete params.limit;
      }

      this.filter = { ...params };
      delete this.filter.limit;
      delete this.filter.page;
      Object.keys(this.filter).forEach(
        (key) =>
          (this.filter[key] == null || this.filter[key] == "") &&
          delete this.filter[key]
      );

      await HttpRda.get("/students?include=cycle", { params })
        .then((res) => {
          this.students = res.data.students;
          this.count = res.data.count;
          this.wasRequestMade = true;
        })
        .catch(() => {
        });
    },

    async searchStudent() {
      this.nameCol = "Tutoria";
      this.currentPage = 1;
      this.listStudents();
    },

    clearFields() {
      this.nameCol = "Tutoria";
      this.search = "";
      this.currentPage = 1;
      this.listStudents();
    },

    clearCycles() {
      this.nameCol = "Tutoria";
      this.cycle = null;
      this.currentPage = 1;
      this.listStudents();
    },

    clearClassromms() {
      this.classroom = null;
      this.currentPage = 1;
      this.listStudents();
    },

    clearStudentsStatus() {
      this.is_active = null;
      this.currentPage = 1;
      this.listStudents();
    },

    setStudent(student) {
      this.studentToEdit = student;
    },

    setStudentPassword(student) {
      this.studentToPassword = student;
    },

    updateStatus(id, status) {
      var data = {
        id: id,
        is_active: status,
      };
      DataService.update(id, data)
        .then((response) => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `Status atualizado com sucesso!`,
          });

          return response;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },

    confirmStudentDeletion(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.deleteStudent(id);
              this.$swal
                .fire("Excluído!", "Aluno foi excluído.", "success")
                .then((response) => {
                  this.listStudents();
                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error;
        });
    },
  },

  watch: {
    currentPage() {
      this.listStudents(this.currentPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  async created() {
    await this.listStudents();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tags_count {
    background-color: #68c5b7;
    font-size: 18px;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    padding: 3px 10px;
    height: max-content;
  }
}
.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}
.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }
  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;
      .comma {
        margin-right: 4px;
      }
      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }
  .cycles {
    display: flex;
    flex-wrap: wrap;
    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}
.outline {
  color: #fff !important;
  background: transparent;
  max-width: 220px !important;
  margin-right: 20px;

  &:hover {
    color: #68c5b7 !important;
  }
}
</style>
