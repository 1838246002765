<template>
  <b-container class="container-rda">
    <b-row class="mb-5">
      <b-col>
        <h2 class="purple bold">Tutoria - Sobre o aluno</h2>
      </b-col>
    </b-row>

    <b-row align="center" v-if="loading">
      <b-col class="mt-5">
        <b-spinner variant="success" label="Carregando..."></b-spinner>
      </b-col>
    </b-row>

    <b-row v-if="this.student !== null">
      <b-col cols="3" v-if="this.student">
        <b-avatar class="mb-4" :src="this.student.User && this.student.User.photo
          ? this.student.User.photo
          : '../../assets/images/user_placeholder.png'
          " size="17rem"></b-avatar>
        <div class="info-student">
          <h4>
            {{
              this.student.User && this.student.User.name
                ? this.student.User.name
                : "Não cadastrado"
            }}
          </h4>
          <ul>
            <li>
              <p v-if="student && student.Classroom && student.Classroom.year_id">
                {{
                  this.student.Cycle && this.student.Cycle.name
                    ? this.student.Cycle.designation
                    : ""
                }}
                <br>
                ({{
                  this.student.year_id
                }}º ano do ciclo)
              </p>

            </li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <b-tabs class="tab-content" content-class="mt-5">
          <!-- Tab Content -->
          <b-tab title="Tutoria">
            <b-row class="mb-5" align-v="center">
              <b-col class="">
                <h4>Observações de tutoria</h4>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <ul class="tutoring-list" v-if="remarks.length > 0">
                  <li v-for="(item, index) in remarks" :key="index">
                    <i v-if="item.teacherName">
                      Professor(a): {{ item.teacherName }} em
                      {{ item.created_at }}
                    </i>
                    <p>
                      {{ item.remarks }}
                    </p>
                    <div class="file-group">
                      <h6>Arquivo para download</h6>
                      <div v-if="item.file_url !== null" :key="index">
                        <a :href="`${item.file_url}`" target="_blank" download>
                          <b-icon icon="file-arrow-down" aria-hidden="true"></b-icon>
                          <span>{{ item.file_name }}</span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <b-row class="box-not-found" v-else>
                  <ul class="message-not-found">
                    <li>
                      <p>Ainda não existe observação para esse aluno.</p>
                    </li>
                  </ul>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
          <!-- Tab Content -->
          <b-tab title="RDA" active>
            <b-row class="rda-tabs" v-if="cycles.length > 0">
              <b-col v-for="(cycle, index) in cycles" :key="index"
                :class="`tab ${cycle.active === true ? 'active' : ''}`" @click="selectRdaTab(index)">
                <b>{{ cycle.designation }}</b>
                <span>{{ cycle.name
                  }}{{ cycle.current === true ? " / Em curso" : "" }}</span>
              </b-col>
            </b-row>

            <b-row align="center" v-if="loadingRDA">
              <b-col class="mt-5">
                <b-spinner variant="success" label="Carregando..."></b-spinner>
              </b-col>
            </b-row>
            <div v-if="!loadingRDA && !viewRda">
              <div v-if="rdaTab && subjects != null">
                <b-row class="rda-tab-content">
                  <b-col cols="8">
                    <h4 class="mb-4">Disciplinas</h4>
                  </b-col>
                  <b-col cols="4">
                    <h4 class="mb-4">Evolução</h4>
                  </b-col>
                </b-row>
                <b-row class="rda-tab-content" v-for="(item, index) in subjects.Subjects" :key="`row-${index}`">
                  <b-col cols="8">
                    <b-row align-v="center" class="list-teacher">
                      <b-col cols="8">
                        <p>{{ item.designation }}</p>
                      </b-col>
                      <b-col>
                        <p v-if="item.status === 'EE'" class="tags warning">
                          EE
                        </p>
                      </b-col>
                      <b-col>
                        <p>
                          {{ item.progress }}
                        </p>
                      </b-col>
                      <b-col cols="1">
                        <button class="viewButton" @click="openViewRDA(item.id)">
                          <ViewIcon />
                        </button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <div class="progress-rda">
                      <p>{{ item.percentage }}%</p>
                      <b :style="{ width: item.percentage + '%' }" :class="`bar ${createSubjectCssClass(
                        item.designation
                      )}`"></b>
                      <span class="lines" v-for="(line, index) in 6" :key="`line-${index}`"></span>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row class="box-not-found">
                  <ul class="message-not-found">
                    <li>
                      <p>
                        Ainda não existem informações de ciclos para esse aluno.
                      </p>
                    </li>
                  </ul>
                </b-row>
              </div>
            </div>

            <div class="viewRDA" v-if="!loadingRDA && viewRda">
              <b-row>
                <b-col cols="12">
                  <span class="back-rda" @click="backTableRda(true)">
                    <BackIcon />
                    <span>Voltar</span>
                  </span>
                </b-col>
                <b-col cols="12">
                  <div class="title-viewRDA">
                    <b>{{ subjectSelected.designation }}</b>
                  </div>
                </b-col>
              </b-row>
              <b-row class="rda-viewlist" v-for="(item, index) in subjectSelected.Competences" :key="`item-${index}`">
                <b-col cols="12">
                  <h5 class="title">
                    {{ index + 1 }} - {{ item.description }}
                  </h5>
                </b-col>
                <b-col cols="12" v-for="(topic, indexS) in item.Topics" :key="`student-${indexS}`">
                  <div class="list-teacher item-rda-viewlist">
                    <div class="cols text-col">
                      <p>
                        {{ index + 1 }}.{{ indexS + 1 }} {{ topic.description }}
                      </p>
                    </div>
                    <div class="cols" v-if="topic.TopicStudents[0].status === 'AN'">
                      <b-button :id="topic.TopicStudents[0].topic_id" class="evidence-btn"
                        @click="showEvidence(topic.TopicStudents[0].pendency)">
                        Pendência
                      </b-button>
                    </div>
                    <div class="cols">
                      <p class="tags warning">
                        {{ topic.TopicStudents[0].status }}
                      </p>
                    </div>
                    <div class="cols">
                      <!-- <p>{{ topic.TopicStudents[0].updated_at }}</p> -->
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-modal id="modal-evidence" hide-footer style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <h3>Pendência</h3>

                <div class="pendency-text">
                  <p>{{ pendencyText }}</p>
                </div>
              </b-modal>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="6">
        <Loading />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../http-rda";
import moment from "moment";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import BackIcon from "@/components/svg/LeftArrowIcon.vue";
import Loading from "../../components/Loading.vue";
import store from "../../store";
export default {
  name: "dashboard-tutoria-aluno",
  components: {
    ViewIcon,
    BackIcon,
    Loading,
  },

  data() {
    return {
      pendencyText: null,
      id: this.$route.params.id,
      cycles: [],
      apenas: true,
      loading: false,
      loadingRDA: false,
      remarks: [],
      student: null,
      tutor: null,
      InitialInterview: null,
      InitialInterviewText: null,
      teacher_id: null,
      allSubjects: [],
      subjects: null,
      subjectSelected: null,
      form: {
        remarks: null,
        parent_view: false,
        files: [],
      },
      formId: Number,
      rdaTab: true,
      viewRda: false,
      tutoringData: null,
      currentUserId: null,
      showForParent: false,
      showForStudent: false,
    };
  },

  methods: {
    async getCurrentUser() {
      this.currentUserId = store.getters["auth/getAuthData"];
      this.currentUserId = this.currentUserId.id;

      await this.getStudentParents();
    },

    showEvidence(pendency) {
      this.pendencyText = pendency;

      this.$bvModal.show("modal-evidence");
    },

    async getStudentParents() {
      const parentsIds = await httpRda
        .get(`/tutoring/student/${this.student.id}/parents`)
        .then((response) => response.data.parentsIds);

      const currentUserIsParent = parentsIds.some(
        (p) => p === this.currentUserId
      );

      const currentUserIsStudent = this.student.User.id === this.currentUserId;

      currentUserIsParent
        ? (this.showForParent = false)
        : (this.showForParent = true);

      currentUserIsStudent
        ? (this.showForStudent = false)
        : (this.showForStudent = true);
    },

    removeSpecialChars(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");

      return text;
    },
    createSubjectCssClass(designation) {
      let aux = designation.toLowerCase();
      aux = aux.replace(/\s/g, "-");
      return this.removeSpecialChars(aux);
    },

    openCreateModal() {
      this.formId = null;
      this.form.remarks = null;
      this.form.parent_view = false;
      this.files = null;
      this.$bvModal.show("modal-register");
    },

    selectRdaTab(clickIndex) {
      this.cycles.map((cycle, index) =>
        index === clickIndex ? (cycle.active = true) : (cycle.active = false)
      );

      this.loadingRDA = true;
      this.rdaTab = true;

      setTimeout(() => {
        this.defineCurrentSubjects();

        this.loadingRDA = false;
        this.viewRda = false;
      }, 100);
    },

    getCycleIndex() {
      return this.cycles.findIndex((cycle) => cycle.active == true);
    },

    defineCurrentSubjects() {
      this.subjects = this.allSubjects.find(
        (subject) => subject.id === this.cycles[this.getCycleIndex()].id
      );
    },

    async getSubjects(id) {
      this.loadingRDA = true;
      try {
        const { data } = await httpRda.get(
          `/tutoring/student/${this.student.id}/subjects/${id}`
        );
        this.subjectSelected = data.subjects[0];
        setTimeout(() => {
          this.loadingRDA = false;
          this.viewRda = true;
        }, 1000);
      } catch (err) {
        this.loadingRDA = false;
      }
      this.loadingRDA = false;
    },

    openViewRDA(id) {
      this.loadingRDA = true;
      this.getSubjects(id);
    },

    backTableRda(status) {
      this.loadingRDA = true;
      this.rdaTab = status;

      setTimeout(() => {
        this.loadingRDA = false;
        this.viewRda = false;
      }, 100);
    },

    async getAllStudentCycles() {
      this.loadingRDA = true;
      await httpRda
        .get(`/tutoring/student/${this.id}/cycles`)
        .then((response) => {
          this.cycles = response.data.cycles;
          this.cycles = this.cycles.map((cycle) => {
            return {
              ...cycle,
              active: cycle.current === true ? true : false,
            };
          });
        });
      this.loadingRDA = false;
    },

    async getStudentSubjects() {
      this.loadingRDA = true;
      const subjectsAll = await httpRda.get(
        `/tutoring/student/${this.id}/allCyclesData`
      );

      let subjects = subjectsAll.data.data;

      subjects.map((data) => {
        data.Subjects.map((subject) => {
          subject.Competences.map((competence) => {
            competence.Topics.map((topic) => {
              topic.TopicStudents.map((item) => {
                if (item.status === "EE") {
                  subject.status = item.status;
                }
              });
            });
          });
        });
      });
      this.allSubjects = subjects;

      this.defineCurrentSubjects();
      this.loadingRDA = false;
    },

    async getAllTutoringData() {
      this.loadingRDA = true;
      const response = await httpRda.get(
        `/tutoring/student/${this.id}/allTutoringData?showForParent=${this.showForParent}&showForStudent=${this.showForStudent}`
      );

      this.tutoringData = response.data.tutoringData;

      this.remarks = this.tutoringData.remarkTutoring.map((remark) => {
        return {
          ...remark,
          created_at: moment(remark?.created_at).format(
            "DD/MM/YYYY [às] hh:mm"
          ),
          updated_at: moment(remark?.updated_at).format(
            "DD/MM/YYYY [às] hh:mm"
          ),
        };
      });

      this.InitialInterview = [];
      for (const i of this.tutoringData.initialInterview) {
        i.created_at = moment(i.created_at).format("DD/MM/YYYY [às] hh:mm");

        i.updated_at = moment(i.updated_at).format("DD/MM/YYYY [às] hh:mm");

        this.InitialInterview.push(i);
      }
      this.loadingRDA = false;
    },

    async getStudentData() {
      const id = this.id;
      if (id) {
        await httpRda
          .get(`/students/${id}?include=parents,cycle`)
          .then(async (res) => {
            this.student = res.data.student;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  async created() {
    await this.getStudentData();
    await this.getCurrentUser();
    await this.getAllTutoringData();
    await this.getAllStudentCycles();
    await this.getStudentSubjects();
  },

  mounted() { },
};
</script>

<style lang="scss">
$artes-visuais: #b2ecf7;
$lingua-estrangeira: #ffc2c2;
$ciencias-da-natureza: #d1e7c0;
$ciencias-humanas: #d8c7ee;
$corpo-e-mente: #ffd97d;
$danca: #ff8b94;
$historia: #ffd9b6;
$lingua-portuguesa: #b2c9f7;
$matematica: #ffc9ed;
$espanhol: #a3e0ce;
$musica: #ffad76;
$cidadania-vida: #e7b3c8;
$teatro: #aedbad;
$ingles: #90a8e6;

.evidence-btn {
  border-radius: 5px;
  background-color: #68c5b7;
  color: white;
  border: none;
}

.info-student {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;

  ul {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      b {
        font-weight: 400;
        font-size: 16px;
        color: #666666;
      }

      p {
        font-size: 18px;
        color: #000;
        margin-bottom: 0;
      }
    }
  }
}

.tab-content {
  .nav-tabs {
    border-bottom: 2px solid #e6e6e6;
    gap: 24px;

    li {
      &:first-child {
        a {
          text-align: left;
          padding: 0;
        }
      }
    }

    .nav-link {
      text-align: center;
      font-size: 18px;
      color: #1a1a1a;
      height: 52px;
      border: 0;
      padding: 0 18px;
      position: relative;

      &.active {
        font-weight: bold;
        background: transparent;

        &:after {
          width: 100%;
        }
      }

      &:after {
        background: $gradient1;
        content: "";
        width: 0;
        height: 4px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50px;
        transition: 0.2s all ease-in-out;
      }
    }
  }
}

.box-not-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
  widows: 100%;
}

.message-not-found {
  width: 50%;
  background: $white;
  border: 1px solid #ededed;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  list-style: none;

  p {
    margin: 0;
  }
}

.btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 5px;

  svg {
    font-size: 175% !important;
  }
}

.obs-info {
  display: flex;
  flex-direction: column;
  text-align: start;
  row-gap: 24px;
  padding: 0;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 70px;
  margin-bottom: 50px;

  i {
    color: #666666;
    font-size: 16px;
  }
}

.notInitialView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 24px;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 70px;
  margin-bottom: 70px;
}

.parent-list {
  ul {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;
      list-style: none;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.tutoring-list {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 32px;
    list-style: none;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    time {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }

    p {
      margin-bottom: 0;
    }

    .file-group {
      background: $white;
      border: 1px solid #ededed;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;
      row-gap: 16px;
      border-radius: 6px;
      padding: 10px;
      box-sizing: border-box;

      h6 {
        text-align: center;
        color: #666;
        flex: 0 0 100%;
        font-size: 14px;
        border-bottom: 2px solid #fafafa;
        padding-bottom: 10px;
        margin-bottom: 0;
      }

      div {
        background: #fff;
        flex: calc(100% / 6);
        max-width: 200px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 8px;
        box-sizing: border-box;
        transition: 0.2s all ease-in-out;

        &:hover {
          box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
          transform: translate(-3px, -3px);
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;

          span {
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }

    .btn-group {
      gap: 10px;
      padding-top: 10px;

      button {
        font-weight: 600;
        font-size: 16px;
        color: $purple;
        background-color: transparent;
        border: 0;
      }
    }
  }
}

#modal-register {
  .modal-dialog {
    max-width: 700px;
  }

  .modal-header {
    border: 0;
    position: relative;

    h5 {
      font-size: 24px;
    }

    .close {
      font-size: 16px;
      color: #fff;
      background: #b3b3b3;
      width: 24px;
      height: 24px;
      border-radius: 50px;
      padding: 0;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
}

form {
  text-align: left;

  .box-ckeckbox {
    background: #ffffff;
    height: 58px;
    border: 1px solid #b3b3b3;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.375rem 0.75rem;
    box-sizing: border-box;
    cursor: pointer;

    .custom-control-input:checked~.custom-control-label {
      &::before {
        background: $purple;
      }

      &::after {
        background: $white;
        left: -35px !important;
        opacity: 1;
      }
    }

    .custom-switch {
      .custom-control-label {
        &::before {
          background: transparent;
          width: 59px;
          height: 26px;
          border-color: $purple;
          border-radius: 100px;
          left: -3.75rem;
        }

        &::after {
          background: $purple;
          width: 18px;
          height: 18px;
          top: 8px;
          left: -54px;
          opacity: 0.5;
        }
      }
    }
  }
}

.wrap-btn-form {
  width: 100%;
  border-bottom: 1px solid #b3b3b3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0;
  margin-bottom: 24px;
  box-sizing: border-box;

  button {
    width: 146px;
  }
}

.informative {
  text-align: left;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #666666;
    margin-bottom: 10px;
  }
}

.rda-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 40px;

  .tab {
    background: linear-gradient(180deg, #fff -16%, #fff 113%);
    flex: 0 0 260px;
    height: 94px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8;
    transition: 0.2s all;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: linear-gradient(180deg, #ef6549 -16%, #b23066 113%);

      b,
      span {
        color: #fff;
      }
    }

    b {
      font-size: 16px;
      color: #666666;
    }

    span {
      font-size: 14px;
      color: #666666;
    }
  }
}

.rda-tab-content {
  padding-left: 0;

  &:last-child {
    .progress-rda {
      .lines {
        height: 100%;
        top: 0;
      }
    }
  }
}

.progress-rda {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  overflow: visible !important;

  &:after {
    content: "";
    background: #f2f2f2;
    width: 100%;
    height: 80px;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 0;
  }

  p {
    font-size: 18px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: 2;
  }

  .bar {
    background: #d8c7ee;
    height: 100%;
    border-radius: 0 10px 10px 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.artes-visuais {
      background: $artes-visuais;
    }

    &.lingua-estrangeira {
      background: $lingua-estrangeira;
    }

    &.ciencias-da-natureza {
      background: $ciencias-da-natureza;
    }

    &.ciencias-humanas {
      background: $ciencias-humanas;
    }

    &.corpo-e-mente {
      background: $corpo-e-mente;
    }

    &.danca {
      background: $danca;
    }

    &.historia {
      background: $historia;
    }

    &.lingua-portuguesa {
      background: $lingua-portuguesa;
    }

    &.matematica {
      background: $matematica;
    }

    &.espanhol {
      background: $espanhol;
    }

    &.musica {
      background: $musica;
    }

    &.cidadania-vida {
      background: $cidadania-vida;
    }

    &.teatro {
      background: $teatro;
    }

    &.ingles {
      background: $ingles;
    }
  }

  .lines {
    width: 1px;
    height: 116%;
    display: block;
    border-left: 1px dashed #b3b3b3;
    position: relative;
    top: 6px;
    z-index: 2;
  }
}

.viewButton {
  background: transparent;
  border: 0;

  svg {
    width: 20px;
  }
}

.tags {
  &.warning {
    background-color: var(--orange);
  }
}

.back-rda {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 40px;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
    height: 18px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    color: #4f4f4f;
    position: relative;
    top: 2px;
  }
}

.title-viewRDA {
  background: linear-gradient(270deg, #6883c5 0%, #36b67d 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 40px;

  b {
    color: #fff;
    font-weight: bold;
  }

  span {
    color: #fff;
  }
}

.rda-viewlist {
  margin-bottom: 30px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #36b67d;
    margin-bottom: 12px;
  }

  .item-rda-viewlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .cols {
      .tags {
        min-width: 40px;
      }
    }

    .text-col {
      flex: 1;
    }
  }
}
</style>
