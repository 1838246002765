<template>
  <b-container class="container-rda">
    <b-row v-if="showPage" class="enrollment">
      <b-col>
        <div class="box-info">
          <div class="title">
            <h2>Informações para requerimento de matrícula</h2>
          </div>
          <div class="content">
            <p>
              Para iniciar o requerimento de matrícula, preencha todos os campos
              do formulário ao lado.
            </p>
            <p class>
              Caso pretenda requerer matrícula para vários alunos precisará
              preencher este formulário apenas uma vez.
            </p>
          </div>
        </div>
        <b-button class="btn-box-info mt-5" href="/matriculas"
          >Já tenho cadastro</b-button
        >
      </b-col>
      <b-col class="create-account">
        <h2 class="purple">Criar conta</h2>

        <b-form ref="form" @submit.prevent="create">
          <b-form-group
            class="requiredField"
            label="Nome completo do responsável pelo aluno (a)"
          >
            <b-form-input
              required
              v-model="formFields.name"
              name="name"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            class="requiredField"
            label="CPF do responsável pelo aluno (a)"
          >
            <b-form-input
              required
              v-model="formFields.cpf"
              :state="verificarCpf"
              name="cpf"
              type="text"
              v-mask="'###.###.###-##'"
            ></b-form-input>
            <p class="purple mt-2" v-if="message.details">
              {{
                message.details.map((m) => m.includes("cpf"))
                  ? "CPF já cadastrado. Tente entrar com a sua conta original."
                  : "Por favor, verifique se todos os campos estão preenchidos."
              }}
            </p>
          </b-form-group>

          <b-form-group
            class="requiredField"
            label="E-mail do responsável pelo aluno (a)"
          >
            <b-form-input
              required
              v-model="formFields.email"
              name="email"
              type="email"
            ></b-form-input>
            <p class="purple mt-2" v-if="message.details">
              {{
                message.details.map((m) => m.includes("email"))
                  ? "E-mail já cadastrado. Tente entrar com a sua conta original."
                  : "Por favor, verifique se todos os campos estão preenchidos."
              }}
            </p>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group class="requiredField" label="Senha">
                <b-form-input
                  required
                  v-model="formFields.password"
                  :state="statePassword"
                  type="password"
                ></b-form-input>
                <p
                  class="purple mt-2"
                  v-if="
                    this.formFields.password.length > 0 &&
                    this.formFields.password.length < 8
                  "
                >
                  A senha deve ter de 8 a 20 caracteres.
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group class="requiredField" label="Telefone 1">
                <b-form-input
                  required
                  v-model="formFields.phone1"
                  type="text"
                  name="phone"
                  label="Telefone 1"
                  v-mask="'(##) #####-####'"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Telefone 2">
                <b-form-input
                  v-model="formFields.phone2"
                  type="text"
                  name="phone-2"
                  label="Telefone 2"
                  v-mask="'(##) #####-####'"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <p class="text-right required"><span>*</span> Campos obrigatórios</p>

          <b-button type="submit" class="mt-5 full" :disabled="!isFormValid">
            {{ loadText }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import utils from "@/utils";
import { mapActions, mapState } from "vuex";
import httpRda from "../../http-rda";

export default {
  name: "CreateAccount",

  data() {
    return {
      showPage: false,
      isFormValid: false,
      message: "",
      loadText: "Salvar e Entrar",
      formFields: {
        name: "",
        cpf: "",
        email: "",
        password: "",
        phone1: "",
        phone2: "",
      },
    };
  },

  watch: {
    formFields: {
      deep: true,
      handler() {
        const form = this.$refs.form;
        if (!form) return;

        this.isFormValid = form.checkValidity();
      },
    },
  },

  computed: {
    ...mapState({
      nodes: (state) => state.nodes.responsibles,
    }),

    verificarCpf() {
      const validarCPF = utils.validateCPF(this.formFields.cpf);
      if (this.formFields.cpf.length > 0) {
        return validarCPF ? true : false;
      } else {
        return null;
      }
    },

    statePassword() {
      if (this.formFields.password.length > 0) {
        return this.formFields.password.length >= 8 ? true : false;
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapActions({
      createResponsible: "enrollment/createResponsible",
      createParent: "enrollment/createParent",
      login: "auth/login",
    }),

    async create() {
      if (!this.sending) {
        this.sending = true;

        this.loadText = "Por favor, aguarde...";
        if (!this.isFormValid) return;

        if (
          this.formFields.relationship === "Pai" ||
          this.formFields.relationship === "Mãe"
        )
          await this.createParentAccount();
        else await this.createResponsibleAccount();

        await this.login({ ...this.formFields });

        this.$router.push({ name: "matriculas" });
        this.sending = false;
      }
    },

    async createParentAccount() {
      const data = utils.createParentBody(this.formFields);
      await this.createParent(data);
    },

    async createResponsibleAccount() {

      const data = utils.createResponsibleBody(this.formFields);
      // await this.createResponsible(data)
      await httpRda
        .post("/responsibles", data)
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel cadastrar!",
            footer:
              "Por favor, verifique se todos os campos estão preenchidos.",
          });
          this.loadText = "Salvar e Entrar";
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
    },
  },

  beforeCreate() {
    const token = this.$route.query.t;
    if (!token) {
      this.$router.push({ name: "link-expirado" });
    } else {
      httpRda
        .post("validate-enrollment-token", {
          token: token,
        })
        .then(() => {
          this.showPage = true;
        })
        .catch(() => {
          this.$router.push({ name: "link-expirado" });
        });
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.highlighted-red {
  background-color: $red2;
  border-radius: 8px;
  color: $white;
  padding: 10px;
}

.enrollment {
  padding-top: 80px;
  padding-bottom: 60px;
  .box-info {
    border-radius: 10px;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    max-width: 580px;
    .title {
      background: $gradient1;
      border-radius: 6px 6px 0 0;
      color: $white;
      padding: 28px 40px;
      text-align: center;
      h2 {
        margin: 0;
      }
    }
    .content {
      color: $grey4;
      font-weight: $bold;
      padding: 60px 40px;
    }
  }
  .create-account {
    h2 {
      padding-bottom: 20px;
    }
    form {
      .form-group {
        margin-bottom: 35px;

        p {
          font-size: 16px;
          margin-left: 15px;
          margin-bottom: 0;
        }

        &.requiredField legend {
          position: relative;
          &:after {
            content: "*";
            color: $orange;
            font-size: 24px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
      // .required {
      //   position: relative;
      //   span {
      //     color: $orange;
      //     font-size: 24px;
      //   }
      //   color: $grey4;
      // }
    }
  }
}
</style>
