var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"timeline-item"},[_c('div',{staticClass:"time-post"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.time))])]),_c('div',{staticClass:"infos-post"},[_c('div',{staticClass:"categories-post"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.resource.type)+" ")])]),_c('div',{staticClass:"description-post"},[(_vm.resource.extension_type == '.pdf')?_c('button',{staticClass:"preview-pdf",on:{"click":_vm.onOpenPreviewModal}},[_c('FileIcon',{attrs:{"color":"#DB3C3C","extenssion":_vm.resource.extension_type}}),_c('span',{staticClass:"label"},[_vm._v("Visualizar")])],1):_vm._e(),(_vm.resource.general_type == 'followup')?_c('p',[_vm._v(" "+_vm._s(_vm.resource.content)+" ")]):_vm._e(),(
          _vm.resource.extension_type == '.png' ||
          _vm.resource.extension_type == '.jpg' ||
          _vm.resource.extension_type == '.gif'
        )?_c('img',{attrs:{"src":_vm.resource.link,"alt":""},on:{"click":_vm.onOpenPreviewModal}}):_vm._e()]),(_vm.edit)?_c('div',{staticClass:"actions-post"},[_c('button',{on:{"click":_vm.editItem}},[_vm._v("editar")]),_c('button',{on:{"click":_vm.removeItem}},[_vm._v("excluir")])]):_vm._e()]),(
      _vm.resource.general_type == 'followup' &&
      !_vm.resource.visible_for_parents &&
      _vm.edit
    )?_c('span',{staticClass:"flag"},[_vm._v(" Não visível para os responsáveis ")]):_vm._e()]),_c('FilesViewModal',{attrs:{"opening":_vm.showPreview,"type":_vm.resource.extension_type,"path":_vm.resource.link},on:{"onClose":_vm.onClosePreviewModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }