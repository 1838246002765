<template>
  <b-container class="container-rda" v-if="selected">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="profile-row">
          <b-col>
            <img v-if="selected.student.User.photo == null || ''" src="@/assets/images/default-photo.png" height="64"
              width="64" alt />
            <img v-else width="64" height="64" :src="`${selected.student.User.photo}`" alt />
            <div class="content-profile">
              <h5>{{ selected.student.User.name }}</h5>
              <p>
                Ciclo: {{ classroom.Cycle.name }} - {{ classroom.year_id }}° ano
              </p>
            </div>
          </b-col>
        </div>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <div class="dash-box contents">
              <p>Conteúdos</p>
              <h1>{{ topics.count }}</h1>
            </div>
          </b-col>
          <b-col v-if="false">
            <div class="dash-box scripts">
              <p>Roteiros</p>
              <h1>{{ syllabuses.count }}</h1>
            </div>
          </b-col>
          <b-col v-if="selected && selected.student && selected.student.id">
            <router-link class="dash-box comments" :to="{
              name: 'faltas-e-presencas',
              params: { student_id: selected.student.id },
            }">
              <p>Frequência</p>
              <h1>{{ attendances.frequency }}%</h1>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="student-data mt-5 pt-5">
      <b-col>
        <h3>Dados</h3>
      </b-col>
      <div class="w-100 mb-4"></div>
      <b-col>
        <h5 class="grey4">Dados do aluno</h5>
      </b-col>
      <div class="w-100 mb-4"></div>
      <b-col>
        <StandardInput type="text" label="Nome" :placeholder="`${selected.student.User.name}`" disabled></StandardInput>
      </b-col>
      <b-col>
        <StandardInput type="email" label="E-mail" :placeholder="`${selected.student.User.email}`" disabled>
        </StandardInput>
      </b-col>
      <b-col>
        <StandardInput type="text" label="Telefone" :placeholder="`${selected.student.User.Phones.length > 0 &&
          selected.student.User.Phones[0]
          ? selected.student.User.Phones[0].number
          : 'Sem registro'
          }`" disabled></StandardInput>
      </b-col>
      <b-col>
        <StandardInput type="text" label="Data de Nascimento" :placeholder="`${selected.student.User.birth_date}`"
          disabled></StandardInput>
      </b-col>

      <div class="w-100"></div>
      <b-col>
        <StandardInput type="text" label="Tutor(a)" :placeholder="`${this.studentTutor ? this.studentTutor : ''}`"
          disabled></StandardInput>
      </b-col>
      <b-col>
        <StandardInput type="text" label="Turma" :placeholder="`${classroom && classroom.designation ? classroom.designation : ''
          }`" disabled></StandardInput>
      </b-col>
      <b-col md="4">
        <StandardInput type="text" label="Ciclo" :placeholder="classroom.Cycle.designation && classroom.Cycle.name
          ? `${classroom.Cycle.designation} (${classroom.Cycle.name}) - ${classroom.year_id}° ano`
          : null
          " disabled></StandardInput>
      </b-col>

      <b-col>
        <StandardInput type="text" label="Data de Matrícula" :placeholder="`${studentEnrollmentCreatedAt}`" disabled>
        </StandardInput>
      </b-col>
      <div class="w-100"></div>
    </b-row>

    <b-row v-if="
      enrollmentData &&
      Array.isArray(enrollmentData) &&
      enrollmentData.length > 0
    ">
      <b-col md="4">
        <StandardInput type="email" label="Guarda" :placeholder="`${String(enrollmentData[0].guardian).toUpperCase()}`"
          disabled></StandardInput>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h5 class="mb-4 grey4">Dados dos responsáveis</h5>
      </b-col>
    </b-row>
    <b-row class="student-data" v-if="ShowParentData">
      <b-col>
        <b-row v-for="parent in parents" v-bind:key="parent.id">
          <b-col>
            <StandardInput type="text" label="Nome" :placeholder="`${parent.Responsible.User.name}`" disabled>
            </StandardInput>
          </b-col>
          <b-col>
            <StandardInput type="text" label="E-mail" :placeholder="`${parent.Responsible.User.email}`" disabled>
            </StandardInput>
          </b-col>

          <b-col>
            <StandardInput type="text" label="Contato" :placeholder="`${parent.Responsible.User.Phones.length > 0
              ? parent.Responsible.User.Phones[0].number
              : 'Sem registro'
              }`" disabled></StandardInput>
          </b-col>
          <b-col>
            <StandardInput type="text" label="Guarda" :placeholder="enrollmentData.find((e) => e.parent_id == parent.id).is_guardian
              ? 'Sim'
              : 'Não'
              " disabled></StandardInput>
          </b-col>
          <b-col>
            <StandardInput type="text" label="Parentesco" :placeholder="`${parent.Responsible.relationship
              ? parent.Responsible.relationship
              : 'Sem registro'
              }`" disabled></StandardInput>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="
        selected.student.parent1_id != null &&
        selected.student.parent2_id != null
      ">
        <b-button class="btn large blue" @click="ShowParentData = !ShowParentData">{{ ShowParentData ? "Fechar" : "Ver mais" }}</b-button>
      </b-col>
      <b-col v-else>
        <p>Não há dados dos responsáveis cadastrados</p>
      </b-col>
    </b-row>

    <b-row class="pt-5 mt-5 mb-4">
      <b-col>
        <h3>Períodos Extras</h3>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn outline blue" @click="openPeriodModal('Novo')">
          Novo período extra</b-button>
      </b-col>
    </b-row>

    <b-row style="margin-top: 20px">
      <b-col>
        <b-container fluid>
          <b-row align-v="center" class="list-teacher" v-for="(period, index) in bimontlyPeriods"
            v-bind:key="period.id">
            <b-col md="4">
              <b-row>
                <b-col>
                  <p>
                    {{ period.name }}
                  </p>

                  <b>
                    {{ period.active ? "Ativo" : null }}
                  </b>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="3">
              <b-row>
                <b-col md="0" class="label-date">
                  <label>Data Inicial</label>
                </b-col>
                <b-col>
                  <Datepicker class="calendar" disabled v-model="period.from_date" placeholder="Selecione"
                    format="dd/MM/yyyy" :use-utc="true" :language="languages[language]"
                    @selected="fixDate('from_date', index)">
                  </Datepicker>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="3">
              <b-row>
                <b-col md="0" class="label-date">
                  <label>Data Final</label>
                </b-col>
                <b-col>
                  <Datepicker class="calendar" disabled v-model="period.to_date" placeholder="Selecione"
                    format="dd/MM/yyyy" :use-utc="true" :language="languages[language]"
                    @selected="fixDate('to_date', index)">
                  </Datepicker>
                </b-col>
              </b-row>
            </b-col>

            <b-col class="text-right">
              <a v-on:click.prevent="edit(period)">
                <EditIcon />
              </a>
            </b-col>

            <b-col class="text-right">
              <a v-on:click.prevent="exclude(index)">
                <DeleteIcon />
              </a>
            </b-col>
          </b-row>

          <b-row align-v="center" class="list-teacher" v-if="bimontlyPeriods.length <= 0 && requestSent">
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>

          <b-row align-v="center" class="list-teacher" v-if="!requestSent">
            <b-col>Carregando</b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <b-row class="pt-5 mt-5 mb-4">
      <b-col>
        <h3>Conteúdos em estudo</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="topicsFormated && topicsFormated.length < 0">
        <p>Não há conteúdos em estudo</p>
      </b-col>
      <b-col v-else>
        <carousel :perPageCustom="[
          [360, 3],
          [1366, 6],
        ]" paginationColor="$grey5">
          <slide v-for="subject in topicsFormated" v-bind:key="subject.id">
            <div @click="showModal(subject)" style="cursor: pointer" class="box-scripts">
              <b-row>
                <b-col>
                  <SubjectsIcon :value="subject.designation" />
                  <h5>{{ subject.designation }}</h5>
                </b-col>
              </b-row>
              <b-row align-v="center" no-gutters>
                <b-col>
                  <span v-if="subject.topics && subject.topics.length > 1">Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span>
                  <span v-if="subject.topics && subject.topics.length == 1">Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span>
                  <span v-if="subject.topics && subject.topics.length == 0">Não há tópicos</span>
                </b-col>

                <div class="w-100"></div>
              </b-row>
            </div>
          </slide>
        </carousel>
      </b-col>
    </b-row>
    <SubjectsProgressBars :percentages="percentages" :subjects="subjects" :desiredPercentage="desiredPercentage" />
    <b-modal id="modal-script" hide-footer style="display: flex; justify-content: center; align-items: center"
      v-if="subjectSelected" :title="subjectSelected.designation">
      <b-row class="rda-viewlist" v-for="(competence, index) in subjectSelected.competences"
        :key="`competence-${index}`">
        <b-col cols="12">
          <h5 class="title">{{ competence.description }}</h5>
        </b-col>
        <b-col cols="12" v-for="(topic, indexS) in competence.topics" :key="`student-${indexS}`">
          <div class="list-teacher item-rda-viewlist">
            <div class="cols text-col">
              <p>{{ topic }}</p>
            </div>

            <div class="cols">
              <p class="tags warning">EE</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-period" hide-footer>
      <template #modal-title>{{ modalMode }} Registro</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-group id="modal-form-observation" label="Descrição" label-for="form-observation">
            <b-input id="form-observation" v-model="periodModalData.name" placeholder="Nome do período"></b-input>
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group id="modal-form-from-date" label="Data Inicial" label-for="form-from-date">
                <Datepicker class="calendar" v-model="periodModalData.from_date" placeholder="Selecione"
                  format="dd/MM/yyyy" :use-utc="true" :language="languages[language]">
                </Datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="modal-form-to-date" label="Data Final" label-for="form-to-date">
                <Datepicker class="calendar" v-model="periodModalData.to_date" placeholder="Selecione"
                  format="dd/MM/yyyy" :use-utc="true" :language="languages[language]">
                </Datepicker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group>
                <b-form-checkbox v-model="periodModalData.active" name="active" value="true" unchecked-value="false">
                  Ativo
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col>
              <b-button class="btn large outline" @click="hidePeriodModal()">Voltar
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button class="btn large blue" @click="submitPeriod()">
                {{ modalMode === "Novo" ? "Criar" : "Atualizar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </b-container>
  <b-container class="mt-5 container-rda" v-else-if="this.$route.params.id">
    <p>Carregando</p>
  </b-container>
  <b-container class="mt-5 container-rda" v-else>
    <p>Nenhum aluno selecionado</p>
  </b-container>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import DataService from "@/services/students.service";
import SubjectsIcon from "@/components/svg/SubjectsIcon.vue";
import StandardInput from "@/components/inputs/StandardInput";
import httpRda from "../../../http-rda";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import SubjectsProgressBars from "@/components/SubjectsProgressBars.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";

export default {
  name: "Início",
  components: {
    Carousel,
    Slide,
    SubjectsIcon,
    StandardInput,
    SubjectsProgressBars,
    Datepicker,
    DeleteIcon,
    EditIcon,
  },
  data() {
    return {
      periodModalData: {
        name: "",
        from_date: "",
        to_date: "",
      },
      bimontlyPeriods: [],
      requestSent: false,
      desiredPercentage: 10,
      topicsFormated: null,
      subjectScript: null,
      subjectSelected: null,
      percentagesDefault: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      percentages: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      language: "ptBR",
      languages: lang,
      modalMode: "Novo",
      studentTutor: null,
      studentId: this.$route.params.id,
      studentEnrollmentCreatedAt: null,
      selected: null,
      ShowParentData: false,
      student: {
        User: {},
      },
      tutor: {
        User: {
          name: "",
        },
      },
      classroom: {
        Cycle: {
          name: "",
        },
      },
      attendances: [],
      syllabuses: [],
      topics: [],
      parents: [],
      enrollmentData: [],
      subjects: [],

      comments: "actives",

      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Alunos",
          href: "/alunos",
        },
        {
          text: "Perfil",
          href: "/perfil",
          active: true,
        },
      ],
    };
  },

  computed: {
    tutors() {
      if (this.tutor) return this.tutor.User.name;
      else return "Sem registro";
    },
  },

  methods: {
    openPeriodModal(mode) {
      this.modalMode = mode;

      this.periodModalData = {
        id: null,
        name: "",
        from_date: "",
        to_date: "",
      };

      this.$bvModal.show("modal-period");
    },

    hidePeriodModal() {
      this.$bvModal.hide("modal-period");
      this.modalMode = null;
    },

    createPeriod() {
      const payload = {
        name: this.periodModalData.name,
        from_date: this.periodModalData.from_date,
        to_date: this.periodModalData.to_date,
      };

      if (typeof this.periodModalData.active === "string") {
        payload.active = this.periodModalData.active === "true";
      }

      httpRda
        .post(`/students/${this.studentId}/extra-periods`, payload)
        .then(() => {
          this.getExtraPeriods();
        })
        .catch(() => {
          this.$bvToast.toast("Erro ao criar período", {
            title: "Erro",
            variant: "danger",
          });
        });

      this.hidePeriodModal();
    },

    submitPeriod() {
      let someEmpty = false;
      Object.keys(this.periodModalData).forEach((key) => {
        if (this.periodModalData[key] === "") {
          someEmpty = true;
          this.$bvToast.toast("Preencha todos os campos", {
            title: "Erro",
            variant: "danger",
          });
          return;
        }
      });

      if (someEmpty) return;

      if (this.modalMode === "Novo") {
        this.createPeriod();
      } else {
        this.updatePeriod();
      }
      this.hidePeriodModal();
    },

    updatePeriod() {
      const payload = {
        name: this.periodModalData.name,
        from_date: this.periodModalData.from_date,
        to_date: this.periodModalData.to_date,
      };

      if (typeof this.periodModalData.active === "string") {
        payload.active = this.periodModalData.active === "true";
      }

      httpRda
        .patch(
          `/students/${this.studentId}/extra-periods/${this.periodModalData.id}`,
          payload
        )
        .then(() => {
          this.getExtraPeriods();
        })
        .catch(() => {
          this.$bvToast.toast("Erro ao atualizar período", {
            title: "Erro",
            variant: "danger",
          });
        });

      this.hidePeriodModal();
    },

    edit(period) {
      this.openPeriodModal("Editar");

      this.periodModalData = {
        id: period.id,
        name: period.name,
        from_date: period.from_date,
        to_date: period.to_date,
        active: period.active,
      };
    },

    exclude(index) {
      const element = this.bimontlyPeriods[index];

      if (!element.id) {
        this.bimontlyPeriods.splice(index, 1);
      } else {
        this.$bvModal
          .msgBoxConfirm("Deseja realmente excluir este período?")
          .then(async (value) => {
            if (value) {
              try {
                await httpRda.delete(
                  `/students/${this.studentId}/extra-periods/${element.id}`
                );
                this.getExtraPeriods();
              } catch (error) {
                this.$bvToast.toast(error.response?.data?.error || "Erro ao excluir período", {
                  title: "Erro",
                  variant: "danger",
                });
              }
            }
          });
      }
    },

    async getExtraPeriods() {
      this.requestSent = false;
      this.bimontlyPeriods = [];

      try {
        const response = await httpRda.get(
          `/students/${this.studentId}/extra-periods`
        );

        const result = response.data.result;

        this.requestSent = true;

        this.bimontlyPeriods = result.length > 0 ? result : [];

        this.requestSent = true;
      } catch (error) {
        this.requestSent = true;
        this.bimontlyPeriods = [];
      }
    },

    async getSubjectScript(student_id) {
      const subjects = await httpRda
        .get(`students/${student_id}/study_script`)
        .then((res) => res.data.subjectScript)
        .catch((err) => err);

      this.subjectScript = subjects;

      const topics = await subjects.reduce((acc, cur) => {
        const designation = cur.designation;
        const competences = cur.competences;

        let topics = cur.competences.reduce((acc, cur) => {
          const topics = cur.topics;
          acc.push(topics);
          return acc;
        }, []);

        topics = topics.flat();

        acc.push({ designation, competences, topics });

        return acc;
      }, []);

      this.topicsFormated = topics;
    },

    showModal(subject) {
      this.$bvModal.show("modal-script");
      this.subjectSelected = subject;
    },
    getSelected(id) {
      DataService.getStudent(id)
        .then((response) => {
          this.selected = response.data;
          this.selected.student.User.birth_date = moment(
            this.selected.student.User.birth_date
          ).format("DD/MM/YYYY");
        })
        .catch((error) => {
          return error;
        });

      this.getSubjectScript(id);

      DataService.getStudentAttendances(id)
        .then((response) => {
          this.attendances = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentClassroomCycle(id)
        .then((response) => {
          this.classroom = response.data.classroom;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentTutor(id)
        .then((response) => {
          this.tutor = response.data.tutor.Teacher.User;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentSyllabus(id)
        .then((response) => {
          this.syllabuses = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentSubjects(id)
        .then((response) => {
          this.subjects = response.data.subjects;
          if (response.data && response.data.desiredPercentage) {
            this.percentages = [];
            this.percentages = [...this.percentagesDefault];

            this.desiredPercentage = Number(response.data.desiredPercentage);

            this.percentages.sort((a, b) => a.number - b.number);
          }
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentTopics(id)
        .then((response) => {
          this.topics = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentParents(id)
        .then((response) => {
          this.parents = response.data.parents;
          this.enrollmentData = response.data.enrollmentData;
        })
        .catch((error) => {
          return error;
        });
    },

    async getStudentTutor() {
      await httpRda
        .get(`/tutoring/student/${this.studentId}/tutor`)
        .then((response) => {
          this.studentTutor = response.data.tutor
            ? response.data.tutor.Teacher.User.name
            : null;
        });
    },

    async getStudentEnrollmentCreatedAt() {
      await httpRda
        .get(`/students/${this.studentId}/enrollment`)
        .then((response) => {
          this.studentEnrollmentCreatedAt = response.data.enrollment_created_at;
        });
    },
  },

  created() {
    this.getSelected(this.$route.params.id);
    this.getStudentTutor();
    this.getStudentEnrollmentCreatedAt();
    this.getExtraPeriods();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.outline {
  color: #68c5b7 !important;
  background: transparent;

  &:hover {
    color: #fff !important;
  }
}

.label-date {
  margin-top: 15px !important;
}

.student-data {
  .form-group {
    margin-bottom: 40px;
  }
}

.rda-viewlist {
  margin-bottom: 30px;

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #36b67d;
    margin-bottom: 12px;
  }

  .item-rda-viewlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .cols {
      .tags {
        min-width: 40px;
      }
    }

    .text-col {
      flex: 1;
    }
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  padding: 28px 10px;
  position: relative;

  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }

  .col {
    display: flex;
    align-items: center;

    img {
      margin-right: 40px;
    }

    .content-profile {
      h5 {
        margin-bottom: 14px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.dash-box {
  border-radius: 19px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;

  p {
    line-height: 16px;
    margin-bottom: 10px;
  }

  h1 {
    margin: 0;
  }

  &.contents {
    background-color: $purple;
  }

  &.scripts {
    background-color: $green1;
  }

  &.comments {
    background-color: $orange;
  }
}

.dash-tasks {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;

  p {
    margin-bottom: 10px;
  }

  h1 {
    margin: 0;
    color: $grey4;
  }

  &:hover {
    background: $gradient1;
    color: $white;
    cursor: pointer;

    h1 {
      color: $white;
    }
  }

  &.active {
    background: $gradient1;
    color: $white;

    h1 {
      color: $white;
    }
  }
}

.task-list {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  position: relative;

  h5 {
    color: $grey4;
    margin: 0 0 4px 0;
  }

  .disciplines {
    color: $grey4;
    font-size: 16px;
    margin: 0;
  }

  &.date {
    h5 {
      background: -webkit-linear-gradient(left, $orange, $purple);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:after {
      content: "";
      background: $gradient1;
      border-radius: 60px;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
    }
  }
}

.owl-dots {
  .owl-dot {
    span {
      background-color: $grey5 !important;
      min-height: 22px;
      min-width: 22px;
      margin: 12px !important;
    }

    &.active {
      span {
        background: $gradient1 !important;
      }
    }
  }
}

.box-scripts {
  background-color: $white;
  border-radius: 19px;
  min-height: 169px;
  padding: 20px 25px 15px;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  svg {
    margin-bottom: 10px;
  }

  h5 {
    color: $grey4;
  }

  span {
    color: $grey3;
    display: block;
    font-size: 16px;
    text-align: left;
  }

  .progress {
    border-radius: 30px;
    height: 7px;

    .progress-bar {
      background-color: $purple;
    }

    &.orange {
      .progress-bar {
        background-color: $orange2;
      }
    }

    &.yellow {
      .progress-bar {
        background-color: $yellow;
      }
    }

    &.red {
      .progress-bar {
        background-color: $red;
      }
    }

    &.green {
      .progress-bar {
        background-color: $green2;
      }
    }
  }

  p {
    color: $grey4;
    font-size: 12px;
    margin: 0;
    text-align: left;
  }
}

.legend {
  color: $grey4;
  margin-bottom: 90px;

  svg {
    margin-right: 20px;
  }
}

.meetings {
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
    width: 100%;
  }
}
</style>
