<template>
  <b-container id="timeline-page" class="container-rda">
    <b-row class="mb-5">
      <b-col>
        <h2 class="purple bold">Linha do tempo</h2>
      </b-col>
    </b-row>

    <b-row v-if="this.student !== null">
      <b-col cols="3">
        <b-avatar class="mb-4" :src="this.student.User && this.student.User.photo
          ? this.student.User.photo
          : '../../assets/images/user_placeholder.png'
          " size="17rem"></b-avatar>
        <div class="info-student">
          <h4>{{ student.User.name }}</h4>
          <ul>
            <li>
              <b>Turma</b>
              <p>
                {{
                  classroom && classroom.Cycle
                    ? classroom.Cycle.designation
                    : ""
                }}
                - {{ classroom && classroom.year_id ? classroom.year_id : "" }}°
                ano
              </p>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <b-row class="filters">
          <b-col md="3" lg="3">
            <SelectSimple v-model="selectedCategory" label="Categoria" :options="allCategories"
              :callback="selectCategoryFilter" />
          </b-col>
          <b-col md="4" lg="4">
            <SelectSimple v-model="selectedExperienceField" label="Campo de Experiência" :options="allFieldsOptions"
              :disabled="selectedExperienceFieldDisabled" :class="{
                'disabled-input': selectedExperienceFieldDisabled
              }" :callback="selectExperienceField" />
          </b-col>
          <b-col md="2" lg="2">
            <SelectSimple v-model="selectedMonth" label="Mês" :options="allMonth" :callback="selectMonthFilter" />
          </b-col>
          <b-col md="3" lg="3" class="wrap-button-new right">
            <b-button @click="openModalNewRegister" class="btn blue mb-5">
              <b-icon-plus />
              Novo registro
            </b-button>
          </b-col>
        </b-row>
        <b-row v-for="(item, index) in items" :key="index">
          <TimelineItem :resource="item" @onRemove="removeItemList(index)" @onEdit="openModalEditRegister(item)" />
        </b-row>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="6">
        <Loading />
      </b-col>
    </b-row>

    <TimelineRegister :title="modalTitle">
      <form @submit.prevent="submitRegister">
        <b-row>
          <b-col md="12" lg="12">
            <b-form-group label="Categoria" class="required">
              <SelectSimple v-model="registerData.general_type" :options="modalTitle === 'Editar registro'
                ? categories
                : allCategories.filter((category) => category.id !== '-1')
                " :callback="selectGeneralType" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="
          registerData.general_type &&
          registerData.general_type.id == 'followup'
        ">
          <b-col md="12" lg="12">
            <b-form-group label="Campo" class="required">
              <SelectSimple v-model="registerData.category" :options="allFields" :callback="selectCategoryField"
                required />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12">
            <b-form-group class="required" description>
              <label for="label">Texto</label>
              <b-form-textarea v-model="registerData.content" type="textarea" placeholder required></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12">
            <UploadFiles :noUploadPreview="false" :allowMultiple="true" v-model="registerData.file" />
          </b-col>
          <b-col md="12" lg="12" style="padding-top: 15px;">
            <div class="wrapper-switch" @click="switchVisibleForParents">
              <b-form-group class="switch_parents">
                <span class="label">Visível para os responsáveis</span>
                <b-form-checkbox v-model="registerData.visible_for_parents" @change="switchVisibleForParents" size="lg"
                  switch required></b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="
          registerData.general_type &&
          registerData.general_type.id != 'followup'
        ">
          <b-col md="12" lg="12">
            <UploadFiles :noUploadPreview="false" v-model="registerData.file" />
          </b-col>
        </b-row>

        <b-row md="12" lg="12" class="footer-modal">
          <b-col class="text-right">
            <b-button class="btn blue" type="submit" :disabled="!allFieldsFilled">
              <span v-if="submitLoading">{{
                modalTitle === "Editar registro"
                  ? "Atualizando..."
                  : "Registrando..."
              }}</span>
              <span v-else>{{
                modalTitle === "Editar registro" ? "Atualizar " : "Criar "
              }}
                Registro</span>
            </b-button>
          </b-col>
        </b-row>
      </form>
    </TimelineRegister>
  </b-container>
</template>

<script>
import Loading from "@/components/Loading.vue";
import SelectSimple from "@/components/inputs/SelectSimple.vue";
import TimelineItem from "@/components/TimelineItem.vue";
import TimelineRegister from "@/components/modals/TimelineRegisterModal.vue";
import UploadFiles from "@/components/inputs/UploadFiles.vue";
import httpRda from "../../http-rda";
import DataService from "@/services/students.service";
import moment from "moment";

export default {
  name: "dashboard-tutoria-aluno",
  components: {
    Loading,
    SelectSimple,
    TimelineItem,
    TimelineRegister,
    UploadFiles,
  },

  data() {
    return {
      id: this.$route.params.id,
      student: null,
      classroom: null,
      allCategories: [],
      allFields: [],
      allFieldsOptions: [
        { id: null, label: "Todos" },
      ],
      categories: [],
      selectedCategory: null,
      allMonth: [
        { id: "-1", label: "Todos" },
        { id: "JAN", label: "Janeiro" },
        { id: "FEV", label: "Fevereiro" },
        { id: "MAR", label: "Março" },
        { id: "ABR", label: "Abril" },
        { id: "MAI", label: "Maio" },
        { id: "JUN", label: "Junho" },
        { id: "JUL", label: "Julho" },
        { id: "AGO", label: "Agosto" },
        { id: "SET", label: "Setembro" },
        { id: "OUT", label: "Outubro" },
        { id: "NOV", label: "Novembro" },
        { id: "DEZ", label: "Dezembro" },
      ],
      selectedMonth: null,
      selectedExperienceField: null,
      modalPreviewFile: false,
      modalEditRegister: false,
      submitLoading: false,
      registerData: {
        id: null,
        category: null,
        general_type: null,
        content: null,
        visible_for_parents: true,
        file: null,
      },
      items: [],
      modalTitle: "",
      filter: {
        category: null,
        month: null,
      },
    };
  },

  computed: {
    allFieldsFilled() {
      if (
        this.registerData.general_type &&
        this.registerData.general_type.id === "followup"
      ) {
        return (
          this.registerData.general_type !== null &&
          this.registerData.category !== null &&
          this.registerData.content !== null &&
          this.registerData.visible_for_parents !== null
        );
      } else {
        return (
          this.registerData.general_type !== null &&
          this.registerData.file !== null
        );
      }
    },

    selectedExperienceFieldDisabled() {
      return (
        this.selectedCategory &&
        this.selectedCategory.id &&
        this.selectedCategory.id !== 'followup' &&
        this.selectedCategory.id !== '-1'
      )
    }
  },

  methods: {
    successMessage(title) {
      this.$toast.open({
        message: title,
        type: "success",
        duration: 2000,
      });
    },

    selectCategoryFilter(data, clearExperienceField = true) {
      this.selectedCategory = data;
      this.filter.type = data;

      if (clearExperienceField !== false) {
        this.selectedExperienceField = { id: null, label: "Todos" };
        this.filter.followup_type_id = null;
      }

      this.getChildhoodFiles();
    },

    selectExperienceField(data) {
      this.selectedExperienceField = data;
      this.filter.followup_type_id = data.id;

      this.selectCategoryFilter(
        {
          id: "followup",
          label: "Acompanhamento individual"
        },
        false
      )
    },

    selectMonthFilter(data) {
      this.selectedMonth = data;

      if (data.id === "-1") {
        this.filter.month = null;
        this.getChildhoodFiles();
        return;
      }

      const year = moment().format("YYYY");
      const beginDate = moment(`${data.id}-${year}`, "MMM-YYYY")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(`${data.id}-${year}`, "MMM-YYYY")
        .endOf("month")
        .format("YYYY-MM-DD");

      this.filter.month = `${beginDate},${endDate}`;

      this.getChildhoodFiles();
    },

    selectGeneralType(data) {
      this.registerData.general_type = data;
      this.registerData.file = null;
    },

    selectCategoryField(data) {
      this.registerData.category = data;
    },

    openModalPreviewFile() {
      this.modalPreviewFile = true;
    },

    openModalNewRegister() {
      this.registerData = {
        id: null,
        category: null,
        general_type: null,
        content: null,
        visible_for_parents: true,
        file: null,
      };
      this.modalTitle = "Novo registro";
      this.$bvModal.show("timeline_register");
    },

    async openModalEditRegister(item) {
      this.registerData = {
        id: null,
        category: null,
        general_type: null,
        content: null,
        visible_for_parents: true,
        file: null,
      };

      this.modalTitle = "Editar registro";
      this.modalEditRegister = true;
      this.$bvModal.show("timeline_register");

      if (item.general_type === "followup") {
        this.categories = this.allCategories.filter(
          (category) => category.id === "followup"
        );
      } else {
        this.categories = this.allCategories.filter(
          (category) => category.id !== "followup"
        );
      }

      if (item.general_type === "followup") {
        await httpRda
          .get(`/childhood/followups/${item.id}`)
          .then((res) => {
            const response = res.data.result;
            this.registerData = {
              id: response.id,
              category: {
                id: response.FollowupType.id,
                label: response.FollowupType.description,
              },
              general_type: this.allCategories.find(
                (category) => category.id === "followup"
              ),
              content: response.body,
              visible_for_parents: response.visible_for_parents,
              file: null,
            };
          })
          .catch((error) => {
            return error;
          });
      } else {
        await httpRda
          .get(`/childhood/files/${item.id}`)
          .then((res) => {
            const response = res.data.result;
            this.registerData = {
              id: response.id,
              general_type: this.allCategories.find(
                (category) => category.id === response.type
              ),
              visible_for_parents: response.visible_for_parents,
              file: {
                name: response.file_name,
                url: response.link,
              },
            };
          })
          .catch((error) => {
            return error;
          });
      }
    },

    removeItemList(index) {
      const itemToRemove = this.items[index];
      const item_id = itemToRemove.id;
      const generalType = itemToRemove.general_type;

      this.$swal
        .fire({
          title: "Tem certeza que deseja remover este item?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              if (generalType === "followup") {
                await httpRda.delete(`/childhood/followups/${item_id}`);
                this.getChildhoodFiles();
              } else {
                await httpRda.delete(`/childhood/files/${item_id}`);
                this.getChildhoodFiles();
              }
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });
        });
    },

    async submitRegister() {
      if (this.allFieldsFilled) {
        const formData = new FormData();

        if (this.registerData.general_type.id === "followup") {
          formData.append("followup_type_id", this.registerData.category.id);
          formData.append("body", this.registerData.content);
          formData.append("visible_for_parents", this.registerData.visible_for_parents);
          formData.append("student_id", Number(this.id));
          if (this.registerData.file) {
            formData.append("file", this.registerData.file);
          }

          if (this.registerData.id) {
            await httpRda
              .patch(`/childhood/followups/${this.registerData.id}`, formData)
              .then(() => {
                this.successMessage("Registro atualizado com sucesso!");
                this.$bvModal.hide("timeline_register");
                this.getChildhoodFiles();
              });
          } else {
            await httpRda.post("/childhood/followups", formData).then(() => {
              this.successMessage("Registro cadastrado com sucesso!");
              this.$bvModal.hide("timeline_register");
              this.getChildhoodFiles();
            });
          }
        } else {
          formData.append("type", this.registerData.general_type.id);
          formData.append("file", this.registerData.file);
          formData.append("student_id", Number(this.id));

          if (this.registerData.id) {
            await httpRda
              .patch(`/childhood/files/${this.registerData.id}`, formData)
              .then(() => {
                this.successMessage("Registro atualizado com sucesso!");
                this.$bvModal.hide("timeline_register");
                this.getChildhoodFiles();
              });
          } else {
            await httpRda.post("/childhood/files", formData).then(() => {
              this.successMessage("Registro cadastrado com sucesso!");
              this.$bvModal.hide("timeline_register");
              this.getChildhoodFiles();
            });
          }
        }
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Preencha todos os campos obrigatórios!",
        });
      }
    },

    switchVisibleForParents() {
      this.registerData.visible_for_parents =
        !this.registerData.visible_for_parents;
    },

    async getStudentData() {
      if (this.id) {
        await httpRda
          .get(`/students/${this.id}?include=parents,cycle`)
          .then(async (res) => {
            this.student = res.data.student;
            if (this.student) {
              if (this.student.cycle_id != 6 && this.student.cycle_id != 8) {
                this.$router.push({
                  name: "dashboard-tutoria-aluno",
                  params: { id: this.id },
                });
              }
            }
          })
          .catch(() => {
          });

        DataService.getStudentClassroomCycle(this.id)
          .then((response) => {
            this.classroom = response.data.classroom;
          })
          .catch((error) => {
            return error;
          });
      }
    },

    async getChildhoodFiles() {
      this.items = await httpRda
        .get(`/childhood/index?student_id=${this.id}`, {
          params: {
            type: this.filter.type ? this.filter.type.id : null,
            month: this.filter.month ? this.filter.month : null,
            followup_type_id: this.filter.followup_type_id
              ? this.filter.followup_type_id
              : null,
          },
        })
        .then((res) => {
          return res.data.result;
        });
    },

    async getFollowupTypes() {
      this.allFields = await httpRda
        .get("/childhood/followup-types")
        .then((res) => {
          return res.data.result;
        });
      this.allFieldsOptions.push(...this.allFields);
    },

    async getCategories() {
      this.allCategories = await httpRda
        .get("/childhood/categories")
        .then((res) => {
          return res.data.result;
        });
    },
  },

  beforeMount() {
    this.getStudentData();
  },

  mounted() {
    this.getCategories();
    this.getFollowupTypes();
    this.getChildhoodFiles();
  },
};
</script>

<style lang="scss">
#timeline-page {
  .filters {
    margin-bottom: 40px;
  }

  .wrap-button-new {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      margin: 32px 0 0 0 !important;
      font-size: 16px;
    }
  }
}

.wrapper-switch {
  cursor: pointer;
}

.disabled-input {
  background-color: #fafafa;
  opacity: 0.3;
}

.switch_parents {
  width: 100%;
  padding: 16px;
  border: 1px solid #b3b3b3;
  border-radius: 0.25rem;
  cursor: default;
  pointer-events: none;

  &>div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      font-size: 18px;
      color: #1a1a1a;
    }
  }

  .custom-control-input:checked~.custom-control-label::before {
    background-color: $purple;
    border-color: $purple;
  }
}

.footer-modal {
  padding-top: 20px;
}
</style>
