<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2 class="purple bold">Alunos</h2>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" :to="{ name: 'alunos' }">Cancelar</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-50">
      <b-col>
        <SelectClassrooms v-model="classroom" :callback="callbackClassrooms"></SelectClassrooms>
      </b-col>
      <b-col>
        <SelectCycles v-model="cycle" :callback="callbackCycles"></SelectCycles>
      </b-col>
      <b-col>
        <form @submit.prevent="search">
          <b-form-group label="Buscar alunos">
            <b-input-group-prepend>
              <b-form-input v-model="searchTerm" type="search" placeholder="Digite aqui" />
            </b-input-group-prepend>
            <b-input-group-text class="clear-fields" v-if="searchTerm != '' || cycle != null || classroom != null"
              v-b-tooltip.hover title="Limpar Pesquisa">
              <b-icon icon="x" @click="clearFields" />
            </b-input-group-text>
          </b-form-group>
        </form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="order-grid-box">
          <p class="order-list" :class="{ desc: descOrder }" v-on:click="orderName">
            Ordenar
            <OrderNameIcon />
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container fluid>
          <b-row class="header-list-teacher" v-if="wasRequestMade && orderbyName.length">
            <b-col md="1"></b-col>
            <b-col md="5">
              <p class="bold">Nome</p>
            </b-col>
            <b-col>
              <p class="bold">Ciclo</p>
            </b-col>
            <b-col>
              <p class="bold">Ativo</p>
            </b-col>
            <b-col>
              <p class="bold">Editar senha</p>
            </b-col>
            <b-col md="1">
              <p class="bold">Editar</p>
            </b-col>
            <b-col md="1">
              <p class="bold">Apagar</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row align-v="center" class="list-teacher" v-for="student in orderbyName" :key="student.id">
            <b-col md="1" class="align-center">
              <b-form-checkbox-group v-model="selectedStudents" value-field="item" :checked="checked">
                <b-form-checkbox v-model="userIds" @click="select" :value="student.id"></b-form-checkbox>
              </b-form-checkbox-group>
            </b-col>
            <b-col md="5" class="name">
              <p class>{{ student.User.name }}</p>
            </b-col>
            <b-col class="cycles">
              <p class="tags" v-if="student.Cycle">
                {{ student.Cycle.name }}
              </p>
              <p class="tags" v-else>Sem Ciclo</p>
            </b-col>
            <b-col class="status disabled">
              <b-form-checkbox switch disabled></b-form-checkbox>
            </b-col>
            <b-col class="editar-senha disabled">
              <LockIcon />
            </b-col>
            <b-col md="1" class="disabled">
              <a>
                <EditIcon />
              </a>
            </b-col>
            <b-col md="1" class="disabled">
              <a>
                <DeleteIcon />
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row align-v="center" class="list-teacher" v-if="wasRequestMade && !orderbyName.length">
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!wasRequestMade">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row class="align-center" v-if="count > perPage && wasRequestMade">
            <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="count" aria-controls="teachersList"
              last-text first-text>
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="mt-5 align-center">
      <b-col>
        <b-button class="btn large outline checkbox">
          <b-form-checkbox @change="selectAll" v-model="allSelected">{{
            allSelected ? "Deselecionar todos" : "Selecionar todos"
            }}</b-form-checkbox>
        </b-button>
      </b-col>
      <b-col class="text-right">
        <b-button class="btn large blue" @click="createCards" :class="{ disabled: selectedStudents <= 0 }">{{ loadText
          }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import DataService from "@/services/students.service";

import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import OrderNameIcon from "@/components/svg/OrderNameIcon.vue";
import LockIcon from "@/components/svg/LockIcon.vue";
import SelectClassrooms from "@/components/inputs/SelectClassrooms.vue";
import SelectCycles from "@/components/inputs/SelectCycles.vue";
import HttpRda from "../../../http-rda";

export default {
  name: "emitir-carteirinha",
  components: {
    EditIcon,
    DeleteIcon,
    LeftArrowIcon,
    OrderNameIcon,
    LockIcon,
    SelectClassrooms,
    SelectCycles,
  },
  data() {
    return {
      selectedStudents: [],
      userIds: [],

      allSelected: false,
      checked: false,
      descOrder: false,
      wasRequestMade: false,
      studentToEdit: {},

      cycle: null,
      classroom: null,
      searchTerm: "",

      students: null,
      currentPage: 1,
      count: 0,
      perPage: 10,

      loadText: "Imprimir PDF",

      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Alunos",
          href: "/alunos",
        },
        {
          text: "Emitir carteirinha",
          href: "/emitir-carteirinha",
          active: true,
        },
      ],
    };
  },

  computed: {
    orderbyName() {
      return [...this.students].sort((a, b) => {
        const order = this.descOrder ? [1, -1] : [-1, 1];

        if (a.User.name < b.User.name) return order[0];
        else if (a.User.name > b.User.name) return order[1];

        return 0;
      });
    },
  },

  methods: {
    ...mapActions({
      getStudents: "community/listStudents",
      getStudent: "community/getStudent",
      deleteStudent: "community/deleteStudent",
      updateStatusStudents: "community/updateStatusStudents",
    }),

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackCycles({ id }) {
      this.cycle = id !== -1 ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    callbackClassrooms({ id }) {
      this.classroom = id !== -1 ? id : null;
      this.currentPage = 1;
      this.listStudents();
    },

    async listStudents(page = 1) {
      const params = {
        limit: this.searchTerm ? null : this.perPage,
        page,
        search: this.searchTerm,
        cycle: this.cycle,
        classroom: this.classroom,
      };

      await HttpRda.get("/students?include=cycle", { params })
        .then((res) => {
          this.students = res.data.students;
          this.count = res.data.count;
          this.wasRequestMade = true;
        })
        .catch(() => {
        });
    },

    search() {
      this.currentPage = 1;
      this.listStudents();
    },

    clearFields() {
      this.searchTerm = "";
      this.cycle = null;
      this.classroom = null;
      this.currentPage = 1;
      this.listStudents();
    },

    selectAll(student) {
      this.userIds = [];

      if (this.allSelected) {
        for (student in this.students) {
          this.userIds.push(this.students[student].id);
        }
      }
    },

    select() {
      this.allSelected = false;
    },

    createCards() {
      var data = {
        students: this.selectedStudents,
      };
      this.loadText = "Por favor, aguarde...";
      DataService.getStudentsCards(data)
        .then((response) => {
          this.selectedStudents = [];
          this.loadText = "Imprimir PDF";

          var date = new Date();

          var newBlob = new Blob([response.data], { type: "application/pdf" });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.href = data;
          link.download = `Carteirinha_${date.getDate()}-${date.getMonth()}-${date.getFullYear()}_${date.getTime()}`;
          link.click();

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `Carteirinha(s) emitida(s) com sucesso!`,
          });

          return response;
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel atualizar o perfil!",
            footer: "Por favor, verifique se os campos estão preenchidos.",
          });

          return error;
        });
    },
  },

  watch: {
    currentPage() {
      this.listStudents(this.currentPage);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  created() {
    this.listStudents();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.disabled {
  filter: grayscale(1);
  opacity: 0.7;
  pointer-events: none;
}

.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;

  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  p {
    margin: 0;
  }

  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;

      .comma {
        margin-right: 4px;
      }

      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }

  .cycles {
    display: flex;
    flex-wrap: wrap;

    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.order-grid-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;

  .order-list {
    align-items: center;
    color: $grey4;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    margin: 0 9px;

    svg {
      margin-left: 15px;
      transition: all 0.15s;
    }

    &.desc {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .order-style {
    border: 1px solid transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 5px;
    display: flex;
    margin: 0 9px;

    button {
      background: $gradient1;
      border: 0;
      line-height: 46px;
      width: 48px;

      svg {
        path {
          fill: $white;
        }
      }

      &.active {
        background-color: $white;
        background: $white;
        border-radius: 5px;

        svg {
          path {
            fill: url(#paint0_linear);
          }
        }
      }
    }
  }
}
</style>
